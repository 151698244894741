import * as Jsonrpc from '@gain/jsonrpc'
import * as Shared from '@gain/rpc/shared-model'

export enum AssetActionRequiredBy {
  Analyst = 'analyst',
  Reviewer = 'reviewer',
}

export enum AssetPipeline {
  New = 'new',
  Shortlisted = 'shortlisted',
  ShortlistedFull = 'shortlistedFull',
  ShortlistedLimited = 'shortlistedLimited',
  ShortlistedDD = 'shortlistedDD',
  Assigned = 'assigned',
  FirstReview = 'firstReview',
  FinalReview = 'finalReview',
  FinalReviewLimited = 'finalReviewLimited',
  Convert = 'convert',
  Update = 'update',
  UpdateReview = 'updateReview',
  CompletedFull = 'completedFull',
  CompletedLimited = 'completedLimited',
  Backlogged = 'backlogged',
}

export enum CustomerUsageReportType {
  CustomerUsageOverall = 'customerUsageOverall',
  CustomerUsageWeekly = 'customerUsageWeekly',
}

export enum FileStatus {
  Unscanned = 'unscanned',
  UnscannedPriority = 'unscannedPriority',
  Safe = 'safe',
  Generated = 'generated',
  Malicious = 'malicious',
  MissingFile = 'missingFile',
}

export enum ProjectPriorityLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum ReportFormat {
  Text = 'text',
  Csv = 'csv',
}

export enum TimeEntryType {
  Full = 'full',
  Limited = 'limited',
  Minimal = 'minimal',
  FirstReview = 'firstReview',
  FinalReviewFull = 'finalReviewFull',
  FinalReviewLimited = 'finalReviewLimited',
  MinimalProfileFinalReview = 'minimalProfileFinalReview',
  UpdatedFull = 'updatedFull',
  UpdatedLimited = 'updatedLimited',
  UpdatedReviewFull = 'updatedReviewFull',
  UpdatedReviewLimited = 'updatedReviewLimited',
  ConversionLimitedToFull = 'conversionLimitedToFull',
  ConversionMinimalToFull = 'conversionMinimalToFull',
  ConversionMinimalToLimited = 'conversionMinimalToLimited',
}

export interface Address {
  readonly id: number
  city: string
  region: string | null
  countryCode: string
  formattedAddress: string
  lng: number
  lat: number
  locationType: string | null
}

export interface Advisor {
  readonly id: number
  readonly live: boolean
  readonly createdAt: string
  readonly updatedAt: string
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  name: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  linkedInUrl: string | null
  linkedinExternalId: string | null
  webUrl: string | null
  founders: string | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  yearFounded: number | null
  advisoryActivities: Shared.AdvisoryActivity[]
  aliases: AdvisorAlias[]
  fteMeasurements: AdvisorFteMeasurement[]
}

export interface AdvisorAlias {
  readonly id: number
  readonly advisorId: number
  alias: string
  order: number
}

export interface AdvisorDealList {
  items: AdvisorDealListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AdvisorDealListItem {
  readonly id: number
  readonly dealId: number
  advisorId: number | null
  advised: Shared.DealAdvisorAdvised
  advisedOn: Shared.AdvisoryActivity[]
  status: string
  publicationDate: string | null
  asset: string | null
  linkedAssetId: number | null
  announcementDate: Date | null
}

export interface AdvisorFteMeasurement {
  readonly id: number
  readonly advisorId: number
  employeeCount: number
  determinedAt: string
}

export interface AdvisorList {
  items: AdvisorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AdvisorListItem {
  readonly id: number
  readonly live: boolean
  readonly createdAt: string
  readonly updatedAt: string
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  name: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  linkedInUrl: string | null
  linkedinExternalId: string | null
  webUrl: string | null
  founders: string | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  yearFounded: number | null
  advisoryActivities: Shared.AdvisoryActivity[]
  fteMeasurements: AdvisorFteMeasurement[]
  aliases: string[]
}

export interface AnnualReport {
  readonly id: number
  readonly legalEntityId: number
  companyName: string | null
  bookYearStart: string | null
  bookYearEnd: string
  publicationDate: string | null
  type: string
  amendment: boolean
  description: string
  comment: string
  revisionDate: string | null
  createdAt: string | null
  isReadyToParse: boolean
  parsedAt: string | null
  parserVersion: number | null
  parseError: string | null
  files: AnnualReportFile[]
  items: AnnualReportItem[]
  financialResults: AnnualReportFinancialResult[]
}

export interface AnnualReportFile {
  readonly id: number
  fileId: number
  documentId: string | null
  type: string | null
  automatedDownload: boolean
  format: string
  language: string | null
  internal: boolean
  createdAt: string
  processedAt: string | null
  processedBy: string | null
  processorVersion: string | null
  processingError: string | null
  readonly filename: string
}

export interface AnnualReportFinancialResult {
  readonly id: number
  readonly annualReportId: number
  year: number
  revenue: number | null
  grossMargin: number | null
  ebitda: number | null
  ebit: number | null
  totalAssets: number | null
  capex: number | null
  debt: number | null
  cash: number | null
  netDebt: number | null
  inventory: number | null
  receivables: number | null
  payables: number | null
  capital: number | null
  fte: number | null
}

export interface AnnualReportItem {
  readonly id: number
  type: string
  title: string
  source: string
  fragmentId: string
  issue: string | null
  fields: AnnualReportItemField[]
}

export interface AnnualReportItemField {
  readonly id: number
  type: string
  title: string
  code: string
  fragmentId: string
  value: number | null
  bool: boolean | null
  text: string | null
  date: Date | null
  issue: string | null
}

export interface AnnualReportList {
  items: AnnualReport[]
  counts: ListCounts
  args: ListArgs
}

export interface ApiKey {
  readonly id: number
  customerId: number
  note: string
  readonly keyPrefix: string
  readonly createdAt: string
  readonly updatedAt: string
  expiresAt: string | null
  readonly key: string
}

export interface ApiKeyList {
  items: ApiKey[]
  counts: ListCounts
  args: ListArgs
}

export interface Article {
  readonly id: number
  readonly published: boolean
  type: Shared.ArticleType
  date: string
  title: string | null
  body: string | null
  largeImageFileId: number | null
  readonly largeImageFileUrl: string | null
  readonly largeImageFilename: string | null
  smallImageFileId: number | null
  readonly smallImageFileUrl: string | null
  readonly smallImageFilename: string | null
  readonly highlightedAssetId: number | null
  readonly highlightedInvestorId: number | null
  readonly highlightedIndustryId: number | null
  regions: string[]
  sector: string | null
  subsector: string | null
  category: Shared.ArticleCategory | null
  linkedAssets: ArticleAsset[]
  linkedIndustries: ArticleIndustry[]
  linkedInvestors: ArticleInvestor[]
  linkedDealId: number | null
}

export interface ArticleAsset {
  readonly id: number
  readonly articleId: number
  assetId: number
  order: number
}

export interface ArticleIndustry {
  readonly id: number
  readonly articleId: number
  industryId: number
  order: number
}

export interface ArticleInvestor {
  readonly id: number
  readonly articleId: number
  investorId: number
  order: number
}

export interface ArticleList {
  items: ArticleListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ArticleListItem {
  id: number
  type: string
  published: boolean
  date: string
  title: string | null
  body: string | null
  regions: string[]
  sector: string | null
  subsector: string | null
  category: string | null
  highlightedAssetId: number | null
  highlightedAssetName: string | null
  smallImageFileUrl: string | null
  largeImageFileUrl: string | null
  highlightedIndustryId: number | null
  linkedDealId: number | null
}

export interface Asset {
  readonly id: number
  readonly live: boolean
  readonly profileLive: boolean
  profileType: Shared.AssetProfileType
  readonly profileInConversion: boolean
  log: string
  linkedinCompanyId: number | null
  readonly subsidiaryPath: number[] | null
  readonly createdAt: string
  readonly updatedAt: string | null
  financialsAt: string | null
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  generalInfo: AssetGeneralInfo | null
  description: AssetDescription | null
  segmentation: AssetSegmentation | null
  market: AssetMarket | null
  rating: AssetRating | null
  readonly financialLatests: AssetLatestFinancials | null
  readonly nextDeal: AssetNextDealPrediction | null
  tags: AssetTag[]
  financialResults: AssetFinancialResult[]
  financialFootnotes: AssetFinancialFootnote[]
  readonly financialPredictions: AssetFinancialPrediction[]
  shareholders: AssetShareholder[]
  pros: AssetPro[]
  cons: AssetCon[]
  managers: AssetManager[]
  competitors: AssetCompetitor[]
  sources: AssetSource[]
  readonly excelFiles: AssetExcelFile[]
  legalEntities: AssetLegalEntity[]
  listedSecurities: AssetListedSecurity[]
  annualReports: AssetAnnualReport[]
  aliases: AssetAlias[]
  fteMeasurements: AssetFteMeasurement[]
  workflow: AssetWorkflow | null
  assignees: AssetAssignee[]
  projects: Project[]
}

export interface AssetAlias {
  readonly id: number
  readonly assetId: number
  alias: string
  order: number
}

export interface AssetAnnualReport {
  annualReportFileId: number
  order: number
  financials: boolean
}

export interface AssetAssignee {
  id: number
  firstName: string
  lastName: string
}

export interface AssetChart {
  readonly id: number
  readonly assetSegmentationId: number
  of: string
  by: string
  periodFrom: number | null
  periodTo: number | null
  order: number
  items: AssetChartItem[]
}

export interface AssetChartItem {
  readonly id: number
  readonly assetChartId: number
  title: string
  shareFrom: number | null
  shareTo: number | null
  sharePct: number | null
  cagr: number | null
  order: number
}

export interface AssetCompetitor {
  readonly assetId: number
  competitorAssetId: number
  order: number
  competitorId: number
}

export interface AssetCon {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetDescription {
  readonly assetId: number
  short: string
  atAGlance: string
  esg: string
  furtherInformation: string
  history: string
  acquisitions: string
  strategy: string
}

export interface AssetExcelFile {
  readonly id: number
  readonly assetId: number
  linkedAt: string
  importedFinancials: boolean
  importedCharts: boolean
  comment: string
}

export interface AssetExcelFileItem {
  id: number | null
  name: string | null
  linkedAt: string | null
  url: string | null
}

export interface AssetExcelFileList {
  assetId: number | null
  items: AssetExcelFileItem[]
}

export interface AssetFinancialFootnote {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetFinancialPrediction {
  readonly id: number
  readonly assetId: number
  year: number
  ebitda: number
  multiple: number
  enterpriseValue: number
  debtMultiple: number
  debtQuantum: number
  equityTicket: number
  details: AssetFinancialPredictionDetails | null
}

export interface AssetFinancialPredictionDetails {
  readonly assetFinancialPredictionId: number
  ebitdaBase: number
  ebitdaYear: number
  ebitdaGrowthPct: number
  ebitdaAvg: boolean
  multipleMarketBase: number
  multipleSectorCorrection: number
  multipleSizeCorrection: number
  multipleGrowthCorrection: number
  multipleThinBusinessModelCorrection: number
  multipleProfitabilityCorrection: number
  multipleCashConversionCorrection: number
  multipleResilienceCorrection: number
  multipleProfileCorrection: number
  multipleOutlierCapCorrection: number
  multipleResultNotRounded: number
  multipleMin: number
  multipleMax: number
  evEbitda: number
  evMultiple: number
  debtMultipleCeiling: number
  debtMultipleFloor: number
  debtMultipleCushion: number
  debtMultipleResilienceMax: number | null
  debtQuantumEbitda: number
  debtQuantumMultiple: number
  equityTicketEv: number
  equityTicketDebt: number
}

export interface AssetFinancialResult {
  readonly id: number
  readonly assetId: number
  year: number
  periodicity: Shared.FinancialResultPeriodicityType
  isForecast: boolean
  revenue: FinancialResultAmount | null
  revenueYoyGrowthPct: number | null
  grossMargin: FinancialResultAmount | null
  ebitda: FinancialResultAmount | null
  ebit: FinancialResultAmount | null
  consolidatedNetIncome: FinancialResultAmount | null
  consolidatedNetIncomeYoyGrowthPct: number | null
  earningsPerShare: FinancialResultAmount | null
  earningsPerShareYoyGrowthPct: number | null
  freeCashFlow: FinancialResultAmount | null
  freeCashFlowYoyGrowthPct: number | null
  cashConversionCycle: FinancialResultAmount | null
  totalAssets: FinancialResultAmount | null
  capex: FinancialResultAmount | null
  debt: FinancialResultAmount | null
  cash: FinancialResultAmount | null
  netDebt: FinancialResultAmount | null
  inventory: FinancialResultAmount | null
  receivables: FinancialResultAmount | null
  payables: FinancialResultAmount | null
  capital: FinancialResultAmount | null
  currentLiabilities: FinancialResultAmount | null
  totalEquity: FinancialResultAmount | null
  fte: FinancialResultAmount | null
  fteGrowthPct: FinancialResultAmount | null
  revenueFteRatio: number | null
  netDebtEbitdaRatio: number | null
  ebitdaMinusCapex: number | null
  returnOnAssets: number | null
  returnOnEquity: number | null
  returnOnCapitalEmployed: number | null
}

export interface AssetFteMeasurement {
  readonly id: number
  readonly assetId: number
  employeeCount: number
  determinedAt: string
}

export interface AssetGeneralInfo {
  readonly assetId: number
  name: string
  ownership: Shared.AssetOwnershipType
  ownershipIsVerified: boolean
  logoFileId: number | null
  readonly logoFileUrl: string | null
  readonly logoFilename: string | null
  lastDealYear: number | null
  lastDealMonth: number | null
  headquarters: string
  sector: string
  subsector: string
  customerBase: Shared.AssetCustomerBaseType[]
  businessActivity: Shared.AssetBusinessActivityType[]
  salesChannel: Shared.AssetSalesChannelType[]
  pricePositioning: Shared.AssetPricePositioningType | null
  headquartersAddressId: number | null
  headquartersAddress: Address | null
  fte: number | null
  fteYear: number | null
  fteRange: string | null
  currency: string | null
  webUrl: string | null
  excelFileId: number | null
  excelFileName: string | null
  esgOutperformer: boolean
  totalFunding: number | null
  totalFundingCurrency: string | null
  yearFounded: number | null
  linkedinExternalId: string | null
}

export interface AssetLatestFinancials {
  readonly assetId: number
  revenue: FinancialLatestResultAmount | null
  grossMargin: FinancialLatestResultAmount | null
  ebitda: FinancialLatestResultAmount | null
  ebit: FinancialLatestResultAmount | null
  consolidatedNetIncome: FinancialLatestResultAmount | null
  earningsPerShare: FinancialLatestResultAmount | null
  freeCashFlow: FinancialLatestResultAmount | null
  cashConversionCycle: FinancialLatestResultAmount | null
  totalAssets: FinancialLatestResultAmount | null
  capex: FinancialLatestResultAmount | null
  debt: FinancialLatestResultAmount | null
  netDebt: FinancialLatestResultAmount | null
  cash: FinancialLatestResultAmount | null
  capital: FinancialLatestResultAmount | null
  inventory: FinancialLatestResultAmount | null
  receivables: FinancialLatestResultAmount | null
  payables: FinancialLatestResultAmount | null
  fte: FinancialLatestResultAmount | null
  ebitdaAvg: number | null
  ebitdaAvgYears: number[]
}

export interface AssetLegalEntity {
  readonly id: number
  assetId: number
  legalEntityId: number
  financials: boolean
  financialsUntilYear: number | null
  isAutomatedAsset: boolean
  order: number
}

export interface AssetList {
  items: AssetListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AssetListItem {
  id: number
  profileType: Shared.AssetProfileType
  live: boolean
  profileLive: boolean
  profileInConversion: boolean
  publicationDate: string | null
  unpublishedAt: string | null
  updatedAt: string | null
  name: string | null
  webUrl: string | null
  linkedinExternalId: string | null
  region: string | null
  ownership: Shared.AssetOwnershipType | null
  subsidiaryPath: number[] | null
  similarity: number
  pipeline: AssetPipeline | null
  priority: boolean | null
  dueDate: string | null
  assigneeIds: number[]
  assignees: AssetAssignee[]
  projectIds: number[]
  projects: AssetProject[]
  lastNoteCreatedAt: string | null
  actionRequiredBy: AssetActionRequiredBy | null
}

export interface AssetListedSecurity {
  readonly id: number
  assetId: number
  listedSecurityId: number
}

export interface AssetManager {
  readonly id: number
  readonly assetId: number
  personId: number | null
  readonly person: Person | null
  position: string | null
  joinYear: number | null
  joinMonth: number | null
  leaveYear: number | null
  leaveMonth: number | null
  order: number
}

export interface AssetManagerList {
  items: AssetManagerListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface AssetManagerListItem {
  readonly id: number
  readonly assetId: number
  personId: number | null
  position: string | null
  joinYear: number | null
  joinMonth: number | null
  leaveYear: number | null
  leaveMonth: number | null
  order: number
}

export interface AssetMarket {
  readonly assetId: number
  size: string
  trends: string
  competition: string
  cagr: number | null
  cagrMedian: number | null
  cagrStart: number | null
  cagrEnd: number | null
  cagrSource: string | null
}

export interface AssetNextDealPrediction {
  readonly assetId: number
  year: number
  viewOnValuation: boolean
}

export interface AssetPro {
  readonly id: number
  readonly assetId: number
  text: string
  order: number
}

export interface AssetProject {
  id: number
  name: string
  color: string
}

export interface AssetRating {
  readonly assetId: number
  growth: number | null
  organicGrowth: number | null
  nonCyclical: number | null
  contracted: number | null
  grossMargin: number | null
  ebitda: number | null
  conversion: number | null
  leader: number | null
  buyAndBuild: number | null
  multinational: number | null
  overall: number | null
}

export interface AssetSegment {
  readonly id: number
  assetSegmentationId: number
  title: string
  subtitle: string
  description: string
  order: number
}

export interface AssetSegmentation {
  readonly assetId: number
  title: string
  subtitle: string
  segments: AssetSegment[]
  charts: AssetChart[]
}

export interface AssetShareholder {
  readonly id: number
  readonly assetId: number
  investorId: number
  strategyId: number | null
  fundId: number | null
  fundConfidence: Shared.InvestorFundConfidence | null
  isCurrentInvestor: boolean
  share: Shared.AssetShareholderShare
  order: number
}

export interface AssetSource {
  readonly id: number
  readonly assetId: number
  title: string
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
  financials: boolean
  business: boolean
  market: boolean
  background: boolean
  order: number
}

export interface AssetTag {
  readonly id: number
  readonly assetId: number
  tagId: number
  tag: string
}

export interface AssetWorkflow {
  readonly assetId: number
  pipeline: AssetPipeline | null
  priority: boolean
  dueDate: string | null
  lastNoteCreatedAt: string | null
  actionRequiredBy: AssetActionRequiredBy | null
  readonly createdAt: string
  readonly updatedAt: string
}

export interface AssetWorkflowAssignee {
  readonly id: number
  assetId: number
  userId: number
  readonly createdAt: string
}

export interface AssetWorkflowProject {
  readonly id: number
  assetId: number
  projectId: number
  readonly createdAt: string
}

export interface AssociatedTag {
  id: number
  name: string
  assetCount: number
}

export interface CompetitorList {
  items: CompetitorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CompetitorListItem {
  linkedAssetId: number
  id: number
  name: string | null
  description: string | null
  headquarters: string | null
  revenue: number | null
  revenueEur: number | null
  revenueYear: number | null
  currency: string | null
  linkedAssetLogoFileUrl: string | null
}

export interface Conference {
  readonly id: number
  name: string
  editions: ConferenceEdition[]
  readonly createdAt: string
  readonly updatedAt: string
}

export interface ConferenceEdition {
  readonly id: number
  name: string | null
  conferenceId: number | null
  conferenceVenueId: number | null
  readonly live: boolean
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  webUrl: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  startDate: string | null
  endDate: string | null
  exhibitorsFileId: number | null
  readonly exhibitorsFileUrl: string | null
  needsScraping: boolean
  exhibitorsImporting: boolean
  exhibitorsScrapedAt: string | null
  exhibitorsImportFailedReason: string | null
  readonly createdAt: string
  readonly updatedAt: string
}

export interface ConferenceEditionList {
  items: ConferenceEditionListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ConferenceEditionListItem {
  readonly id: number
  name: string | null
  readonly live: boolean
  exhibitorsScrapedAt: string | null
  needsScraping: boolean
  venueCountry: string | null
  venueName: string | null
  startDate: string | null
  exhibitorsCount: number
}

export interface ConferenceExhibitor {
  readonly id: number
  conferenceEditionId: number
  name: string
  webUrl: string | null
  linkedAssetId: number | null
}

export interface ConferenceExhibitorList {
  items: ConferenceExhibitorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ConferenceExhibitorListItem {
  readonly id: number
  conferenceEditionId: number
  name: string
  webUrl: string | null
  linkedAssetId: number | null
  linkedAssetName: string | null
}

export interface ConferenceList {
  items: ConferenceListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ConferenceListItem {
  readonly id: number
  name: string
  readonly createdAt: string
  readonly updatedAt: string
}

export interface ConferenceVenue {
  readonly id: number
  name: string
  countryCode: string
  locationId: number | null
  location: Address | null
  webUrl: string | null
  editionsCount: number | null
  readonly createdAt: string
  readonly updatedAt: string
}

export interface ConferenceVenueList {
  items: ConferenceVenueListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ConferenceVenueListItem {
  readonly id: number
  name: string
  country: string
  address: string | null
  readonly createdAt: string
  readonly updatedAt: string
}

export interface CurrencyList {
  items: CurrencyListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CurrencyListItem {
  id: number
  name: string
  title: string
  symbol: string
  updatedAt: string | null
  display: Shared.CurrencyDisplayType
  toEur: number
  allowUserSelect: boolean
}

export interface Customer {
  readonly id: number
  readonly deactivated: boolean
  name: string
  shortName: string
  defaultUserCurrency: string
  investorId: number | null
  logoFileId: number | null
  deactivatedAt: string | null
  readonly logoFileUrl: string | null
  readonly logoFilename: string | null
  subscription: CustomerSubscription | null
  securitySettings: CustomerSecuritySettings | null
}

export interface CustomerList {
  items: CustomerListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface CustomerListItem {
  readonly id: number
  deactivated: boolean
  name: string
  shortName: string
  defaultUserCurrency: string
  logoFileId: number | null
  readonly logoFileUrl: string | null
  investorId: number | null
  readonly investorName: string | null
  readonly usersTotal: number
  readonly usersNotInvited: number
  readonly usersInvited: number
  readonly usersActive: number
  readonly usersDeactivated: number
  readonly usersActiveLimit: number
}

export interface CustomerSecuritySettings {
  readonly customerId: number
  maxPeriodOfInactivity: number | null
  maxFailedLoginAttempts: number | null
  passwordMinLength: number
  passwordRequireLowerChar: boolean
  passwordRequireUpperChar: boolean
  passwordRequireDigit: boolean
  passwordRequirePunctChar: boolean
  passwordDisallowWhitespace: boolean
  passwordNotLastN: number | null
  tokenExpiration: number | null
  ipWhitelistEnabled: boolean
  ipWhitelistCidrRanges: string[]
  ssoEnabled: boolean
  workOsOrganisationId: string | null
  ssoDomains: string[]
}

export interface CustomerSubscription {
  readonly customerId: number
  trial: boolean
  maxActiveUsers: number
  featureExportAssetsMaxExportsPerMonth: number
  featureExportAssetsMaxItemsPerExport: number
  featureExportDealsMaxExportsPerMonth: number
  featureExportDealsMaxItemsPerExport: number
  featureExportOwnersMaxExportsPerMonth: number
  featureExportOwnersMaxItemsPerExport: number
  featureExportAdvisorsMaxExportsPerMonth: number
  featureExportAdvisorsMaxItemsPerExport: number
  featureExportFactsheetMaxPerMonth: number
  featureExportAnnualReportMaxPerMonth: number
  featureExportIndustryMaxPerMonth: number
  featureExportFinancialsMaxPerMonth: number
  featureSessionTracking: boolean
  featureSessionTrackingTimeout: number
}

export interface Date {
  year: number
  month: number | null
  day: number | null
}

export interface Deal {
  readonly id: number
  readonly status: Shared.DealItemStatus
  readonly publicationDate: string | null
  asset: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  division: string | null
  reasons: Shared.DealReason[]
  dealStatus: Shared.DealStatus | null
  announcementDate: Date | null
  currency: string | null
  linkedAssetId: number | null
  businessActivities: string | null
  fte: DealFactFloat | null
  ev: DealFactFloat | null
  revenue: DealFactFloat | null
  ebitda: DealFactFloat | null
  ebit: DealFactFloat | null
  totalAssets: DealFactFloat | null
  evEbitdaMultiple: DealFactFloat | null
  evEbitMultiple: DealFactFloat | null
  evRevenueMultiple: DealFactFloat | null
  evTotalAssetsMultiple: DealFactFloat | null
  equity: DealFactFloat | null
  fundingRoundAmountRaised: DealFactFloat | null
  fundingRoundPreMoneyValuation: DealFactFloat | null
  fundingRoundPostMoneyValuation: DealFactFloat | null
  fundingRoundType: Shared.DealFundingRoundType | null
  readonly highlightedBuyerId: number | null
  readonly highlightedSellerId: number | null
  buyers: DealBuyer[]
  sellers: DealSeller[]
  sources: DealSource[]
  notes: DealNote[]
  dealType: Shared.DealType | null
  dealroomFundingId: number | null
  advisors: DealAdvisor[]
}

export interface DealAdvisor {
  readonly id: number
  readonly dealId: number
  advisorId: number | null
  advised: Shared.DealAdvisorAdvised
  advisedOn: Shared.AdvisoryActivity[]
}

export interface DealBuyer {
  order: number
  type: Shared.DealSideType
  reason: Shared.DealReason | null
  name: string | null
  division: string | null
  region: string | null
  linkedInvestorId: number | null
  linkedStrategyId: number | null
  linkedFundId: number | null
  linkedFundConfidence: Shared.InvestorFundConfidence | null
  linkedAssetId: number | null
  share: DealFactSideShare | null
  sharePct: DealFactFloat | null
  leadingParty: boolean
}

export interface DealFactFloat {
  value: number
  confidence: Shared.DealFactConfidence
  date: Date | null
}

export interface DealFactSideShare {
  value: Shared.DealSideShare
  confidence: Shared.DealFactConfidence
  date: Date | null
}

export interface DealList {
  items: DealListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface DealListItem {
  id: number
  status: Shared.DealItemStatus
  publicationDate: string | null
  asset: string | null
  region: string | null
  sector: string | null
  subsector: string | null
  currency: string | null
  division: string | null
  dealStatus: Shared.DealStatus | null
  linkedAssetId: number | null
  announcementDateYear: number | null
  announcementDateMonth: number | null
  buyerAssetIds: number[]
  buyerInvestorIds: number[]
  sellerAssetIds: number[]
  sellerInvestorIds: number[]
  dealType: Shared.DealType | null
  dealroomFundingId: number | null
}

export interface DealNote {
  order: number
  text: string
}

export interface DealSeller {
  order: number
  type: Shared.DealSideType
  reason: Shared.DealReason | null
  name: string | null
  division: string | null
  region: string | null
  linkedInvestorId: number | null
  linkedStrategyId: number | null
  linkedFundId: number | null
  linkedFundConfidence: Shared.InvestorFundConfidence | null
  linkedAssetId: number | null
  share: DealFactSideShare | null
  sharePct: DealFactFloat | null
  leadingParty: boolean
}

export interface DealSource {
  order: number
  title: string
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
}

export interface DownloadAnnualReportsResult {
  count: number
  errors: number
  log: string
}

export interface EnumInfo {
  name: string
  summary: string
  description: string
  type: string
  values: Value[]
}

export interface ErrorInfo {
  code: number
  description: string
}

export interface FileInfo {
  id: number
  name: string
  originalName: string
  mimeType: string
  url: string
  md5: string
  status: FileStatus
  secure: boolean
}

/** Filter represents a set of conditions to filter a list on. Typically, top-level filters are given as an array of filters (`[]Filter`) which are combined using the boolean `and`-operator.

Arbitrary boolean combinations of filters can be constructed using the `and`, `or` and `not` operators. For example, below is a complex filter for the asset list:

~~~json
{
	"filters": [
		{
			"operator": "or",
			"value": [
				{
					"field": "region",
					"operator": "=",
					"value", "NL"
				},
				{
					"field": "region",
					"operator": "=",
					"value", "DE"
				},
			],
		},
		{
			"field": "revenue",
			"operator": ">",
			"value": 10,
		}
	],
	"sort": "revenue",
	"limit": 20,
	"page": 2
}
~~~

Note that the top-level filters is a array of filters that is implicitly combined as an `and`-boolean. To create a top-level `or`-filter, add it as the single top-level filter. 
 */
export interface Filter {
  field: string
  operator: string
  value: any
}

export interface FinancialLatestResultAmount {
  year: number
  amount: number
  amountType: Shared.FinancialResultAmountType
  readonly pctRevenue: number | null
  estimated: boolean
}

export interface FinancialResultAmount {
  amount: number
  amountType: Shared.FinancialResultAmountType
  readonly pctRevenue: number | null
  estimated: boolean
}

export interface ImportInvestorFundsResult {
  rowCount: number
  importedItems: ImportedInvestorFundItem[]
  skippedItems: SkippedInvestorFundItem[]
}

export interface ImportedInvestorFundItem {
  rowIndex: number
  fundId: number
  fundName: string
}

export interface Industry {
  readonly id: number
  readonly status: Shared.IndustryStatus
  readonly publicationDate: string | null
  generalInfo: IndustryGeneralInfo | null
  charts: IndustryChart[]
  keyTakeaways: IndustryKeyTakeaway[]
  assets: IndustryAsset[]
  market: IndustryMarket | null
  outlook: IndustryOutlook | null
  deals: IndustryDeals | null
  pros: IndustryPro[]
  cons: IndustryCon[]
  sources: IndustrySource[]
  factSheetFileCreatedAt: string | null
  factSheetFileId: number | null
}

export interface IndustryAsset {
  order: number
  assetId: number
  segmentId: number | null
}

export interface IndustryAssetList {
  items: IndustryAssetListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface IndustryAssetListItem {
  id: number
  title: string
  assetId: number
  assetName: string | null
  segmentId: number | null
  segmentName: string | null
}

export interface IndustryChart {
  order: number
  type: Shared.IndustryChartType | null
  fileId: number | null
  readonly filename: string | null
  readonly fileUrl: string | null
}

export interface IndustryCon {
  order: number
  text: string
}

export interface IndustryDeals {
  recentMAndA: string | null
  assetAvailability: string | null
}

export interface IndustryGeneralInfo {
  date: string | null
  title: string
  sector: string | null
  subsector: string | null
  regions: string[]
  tags: string[]
  smallImageFileId: number | null
  readonly smallImageFilename: string | null
  readonly smallImageFileUrl: string | null
  largeImageFileId: number | null
  readonly largeImageFilename: string | null
  readonly largeImageFileUrl: string | null
  emailImageFileId: number | null
  readonly emailImageFilename: string | null
  readonly emailImageFileUrl: string | null
  coverImageFileId: number | null
  readonly coverImageFilename: string | null
  readonly coverImageFileUrl: string | null
  scope: string | null
}

export interface IndustryKeyTakeaway {
  order: number
  text: string
}

export interface IndustryList {
  items: IndustryListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface IndustryListItem {
  id: number
  status: string
  publicationDate: string | null
  date: string | null
  title: string | null
  sector: string | null
  subsector: string | null
  regions: string[]
  smallImageFileId: number | null
  smallImageFileUrl: string | null
}

export interface IndustryMarket {
  definition: string | null
  structure: string | null
  segments: IndustryMarketSegment[]
  esg: string | null
}

export interface IndustryMarketSegment {
  id: number
  order: number
  name: string
  text: string
  ratingEnvironmental: number | null
  ratingSocial: number | null
  ratingOverall: number | null
}

export interface IndustryOutlook {
  sizeAndGrowth: IndustryOutlookSizeAndGrowth[]
  positiveDrivers: IndustryOutlookPositiveDriver[]
  negativeDrivers: IndustryOutlookNegativeDriver[]
}

export interface IndustryOutlookNegativeDriver {
  order: number
  text: string
}

export interface IndustryOutlookPositiveDriver {
  order: number
  text: string
}

export interface IndustryOutlookSizeAndGrowth {
  order: number
  text: string
}

export interface IndustryPro {
  order: number
  text: string
}

export interface IndustrySource {
  order: number
  title: string | null
  language: string | null
  publisher: string | null
  publicationYear: number | null
  publicationMonth: number | null
  url: string | null
  segment: boolean | null
  outlook: boolean | null
  assessment: boolean | null
  deals: boolean | null
  esg: boolean | null
}

export interface Investor {
  readonly id: number
  readonly live: boolean
  readonly publicationDate: string | null
  name: string
  shortName: string | null
  description: string | null
  url: string | null
  logoFileId: number | null
  readonly logoFileUrl: string | null
  readonly logoFilename: string | null
  readonly liveFundsCount: number
  readonly liveFundSizeEur: number | null
  readonly dryPowderMinEur: number | null
  readonly dryPowderMaxEur: number | null
  readonly fte: number | null
  strategies: InvestorStrategy[]
  funds: InvestorFund[]
  aliases: InvestorAlias[]
  fteMeasurements: InvestorFteMeasurement[]
  flagshipFundId: number | null
  latestFundId: number | null
  fundsRaisedLastFiveYears: number | null
  operationalHqCity: string | null
  operationalHqCountryCode: string | null
  yearFounded: number | null
  foundedBy: string | null
  linkedInUrl: string | null
  linkedinExternalId: string | null
  onlyIncludeCurated: boolean
}

export interface InvestorAlias {
  readonly id: number
  readonly investorId: number
  alias: string
  order: number
}

export interface InvestorFteMeasurement {
  readonly id: number
  readonly investorId: number
  employeeCount: number
  determinedAt: string
}

export interface InvestorFund {
  id: number
  name: string
  fundraisingStatus: Shared.InvestorFundFundraisingStatus | null
  fundSize: number | null
  readonly fundSizeEur: number | null
  targetFundSize: number | null
  firstCloseSize: number | null
  hardCapSize: number | null
  currency: string
  vintageDate: Date | null
  launchDate: Date | null
  firstCloseDate: Date | null
  finalCloseDate: Date | null
  order: number
  source: string
  continuationFund: boolean
  singleDealFund: boolean
  notes: InvestorFundNote[]
  dryPowderMin: number | null
  dryPowderMax: number | null
  investorId: number
  investorStrategyId: number | null
  netIrr: number | null
  netIrrDate: string | null
  grossIrr: number | null
  grossIrrDate: string | null
  twr: number | null
  twrDate: string | null
  tvpi: number | null
  tvpiDate: string | null
  moic: number | null
  moicDate: string | null
  dpi: number | null
  dpiDate: string | null
  rvpi: number | null
  rvpiDate: string | null
}

export interface InvestorFundList {
  items: InvestorFundListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorFundListItem {
  id: number
  name: string
  fundraisingStatus: Shared.InvestorFundFundraisingStatus | null
  fundSize: number | null
  readonly fundSizeEur: number | null
  targetFundSize: number | null
  firstCloseSize: number | null
  hardCapSize: number | null
  currency: string
  vintageDate: Date | null
  launchDate: Date | null
  firstCloseDate: Date | null
  finalCloseDate: Date | null
  order: number
  source: string
  continuationFund: boolean
  singleDealFund: boolean
  notes: InvestorFundNote[]
  dryPowderMin: number | null
  dryPowderMax: number | null
  investorId: number
  investorStrategyId: number | null
  netIrr: number | null
  netIrrDate: string | null
  grossIrr: number | null
  grossIrrDate: string | null
  twr: number | null
  twrDate: string | null
  tvpi: number | null
  tvpiDate: string | null
  moic: number | null
  moicDate: string | null
  dpi: number | null
  dpiDate: string | null
  rvpi: number | null
  rvpiDate: string | null
}

export interface InvestorFundNote {
  order: number
  text: string
}

export interface InvestorList {
  items: InvestorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorListItem {
  readonly id: number
  readonly live: boolean
  publicationDate: string | null
  name: string
  url: string | null
  operationalHqCountryCode: string | null
  shortName: string | null
  aliases: string[]
  logoFileUrl: string | null
}

export interface InvestorStrategy {
  id: number
  name: string
  description: string
  order: number
  currency: string | null
  primaryClassification: Shared.InvestorStrategyClassification | null
  secondaryClassification: Shared.InvestorStrategyClassification | null
  tertiaryClassification: Shared.InvestorStrategyClassification | null
  investmentTicketSizeMin: number | null
  investmentTicketSizeMax: number | null
  evRangeMin: number | null
  evRangeMax: number | null
  revenueRangeMin: number | null
  revenueRangeMax: number | null
  ebitdaRangeMin: number | null
  ebitdaRangeMax: number | null
  preferredEquityStakeMinPct: number | null
  preferredEquityStakeMaxPct: number | null
  preferredStake: Shared.InvestorStrategyPreferredStake
  source: string | null
  investorId: number
  latestFundId: number | null
}

export interface InvestorStrategyList {
  items: InvestorStrategyListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface InvestorStrategyListItem {
  id: number
  name: string
  description: string
  order: number
  currency: string | null
  primaryClassification: Shared.InvestorStrategyClassification | null
  secondaryClassification: Shared.InvestorStrategyClassification | null
  tertiaryClassification: Shared.InvestorStrategyClassification | null
  investmentTicketSizeMin: number | null
  investmentTicketSizeMax: number | null
  evRangeMin: number | null
  evRangeMax: number | null
  revenueRangeMin: number | null
  revenueRangeMax: number | null
  ebitdaRangeMin: number | null
  ebitdaRangeMax: number | null
  preferredEquityStakeMinPct: number | null
  preferredEquityStakeMaxPct: number | null
  preferredStake: Shared.InvestorStrategyPreferredStake
  source: string | null
  investorId: number
  latestFundId: number | null
}

export interface Issue {
  type: string
  path: string
  message: string
}

export interface LegalEntity {
  readonly id: number
  name: string
  nameYear: number
  region: string
  externalId: string
  previousNames: string[]
  sector: string
  subsector: string
  currency: string
  automaticallyCreated: boolean
  linkedinCompanyId: number | null
  globalUltimateOwnerId: number | null
  shouldCheckFinancials: boolean
  annualReportsCheckedAt: string | null
  financialsUpdatedAt: string | null
  createdAt: string | null
  assets: AssetLegalEntity[]
  sectorCodes: LegalEntitySectorCode[]
  uRLs: LegalEntityUrl[]
  annualReports: AnnualReport[]
  financialResults: LegalEntityFinancialResult[]
  rating: LegalEntityRating | null
  readonly financialLatests: LegalEntityLatestFinancials | null
  listOfShareholders: ListOfShareholders[]
  legalEntityShareholders: LegalEntityShareholder[]
  tags: LegalEntityTag[]
}

export interface LegalEntityFinancialResult {
  readonly id: number
  readonly legalEntityId: number
  readonly profitAndLossFromAnnualReportId: number | null
  readonly balanceFromAnnualReportId: number | null
  readonly capexFromAnnualReportId: number | null
  readonly cashFlowFromAnnualReportId: number | null
  readonly notesOperatingProfitUKFromAnnualReportId: number | null
  year: number
  revenue: number | null
  revenueYoyGrowthPct: number | null
  grossMargin: number | null
  grossMarginPctRevenue: number | null
  ebitda: number | null
  ebitdaPctRevenue: number | null
  ebit: number | null
  ebitPctRevenue: number | null
  totalAssets: number | null
  capex: number | null
  debt: number | null
  cash: number | null
  netDebt: number | null
  inventory: number | null
  receivables: number | null
  payables: number | null
  capital: number | null
  fte: number | null
  fteGrowthPct: number | null
}

export interface LegalEntityLatestFinancials {
  readonly legalEntityId: number
  revenue: number | null
  revenueYear: number | null
  grossMargin: number | null
  grossMarginYear: number | null
  ebitda: number | null
  ebitdaYear: number | null
  ebitdaAvg: number | null
  ebitdaPctRevenue: number | null
  ebitdaPctRevenueAvg: number | null
  fte: number | null
  fteYear: number | null
  revenueYoyGrowthPctMin: number | null
  revenueYoyGrowthPctMax: number | null
  revenueResults: number[]
  revenueYears: number[]
  grossMarginResults: number[]
  grossMarginYears: number[]
  ebitdaResults: number[]
  ebitdaYears: number[]
  ebitdaPctRevenueResults: number[]
  ebitdaPctRevenueYears: number[]
  fteResults: number[]
  fteYears: number[]
}

export interface LegalEntityList {
  items: LegalEntityListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface LegalEntityListItem {
  id: number
  name: string
  region: string
  externalId: string
  annualReports: number
  linkedAssets: number
}

export interface LegalEntityRating {
  readonly legalEntityId: number
  growth: number | null
  grossMargin: number | null
  ebitda: number | null
  conversion: number | null
  overall: number | null
}

export interface LegalEntitySectorCode {
  readonly id: number
  legalEntityId: number
  sectorCodeId: number
  order: number
}

export interface LegalEntityShareholder {
  readonly id: number
  legalEntityId: number
  type: string
  name: string
  region: string
  shareholderExternalId: string
  confirmedOn: string | null
  percentageShareMin: number | null
  percentageShareMax: number | null
  percentageShareExact: number | null
  readonly linkedLegalEntityId: number | null
}

export interface LegalEntityTag {
  readonly id: number
  readonly legalEntityId: number
  readonly tagId: number
  readonly tag: string
}

export interface LegalEntityUrl {
  readonly id: number
  readonly legalEntityId: number
  uRL: string
  domain: string
  method: Shared.LegalEntityUrlMethod
  source: Shared.LegalEntityUrlSource
  checkedAt: string | null
  order: number
}

export interface ListArgs {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListCounts {
  total: number
  filtered: number
}

export interface ListOfShareholders {
  id: number
  legalEntityId: number
  fileId: number | null
  documentDate: string | null
  checkedAt: string
}

export interface ListedSecurityList {
  items: ListedSecurityListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ListedSecurityListItem {
  readonly id: number
  name: string | null
  countryIso2: string | null
}

export interface ListedSecuritySuggestion {
  id: number
  name: string | null
  countryIso2: string | null
  linkedAssetId: number | null
  linkedAssetName: string | null
}

export interface MethodInfo {
  name: string
  params: PropertyInfo[]
  result: string
  summary: string
  description: string
  public: boolean
}

export interface Note {
  readonly id: number
  readonly createdAt: string
  readonly updatedAt: string
  userId: number | null
  assetId: number | null
  dealId: number | null
  tab: string
  content: string
}

export interface NoteList {
  items: NoteListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface NoteListItem {
  readonly id: number
  readonly createdAt: string
  readonly updatedAt: string
  userId: number | null
  assetId: number | null
  dealId: number | null
  tab: string
  content: string
  fullName: string
}

export interface Person {
  readonly id: number
  firstName: string
  live: boolean
  publishedAt: string | null
  unpublishedAt: string | null
  lastName: string
  birthYear: number | null
  nationality: string | null
  linkedInUrl: string | null
  investorId: number | null
}

export interface PersonList {
  items: PersonListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface PersonListItem {
  readonly id: number
  readonly fullName: string
  firstName: string
  lastName: string
  birthYear: number | null
  nationality: string | null
  linkedInUrl: string | null
  investorId: number | null
  readonly investorName: string | null
  readonly live: boolean
  readonly publishedAt: string | null
  readonly unpublishedAt: string | null
  assets: PersonListItemAsset[]
}

export interface PersonListItemAsset {
  id: number
  name: string
  joinYear: number | null
  joinMonth: number | null
  position: string | null
}

export interface Preview {
  subject: string
  body: string
}

export interface Project {
  readonly id: number
  readonly createdAt: string
  readonly updatedAt: string
  name: string
  color: string
  priorityLevel: ProjectPriorityLevel
}

export interface ProjectList {
  items: ProjectListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface ProjectListItem {
  readonly id: number
  readonly createdAt: string
  readonly updatedAt: string
  name: string
  color: string
  priorityLevel: ProjectPriorityLevel
  assetCount: number
}

export interface PropertyInfo {
  name: string
  type: string
  readonly: boolean
  description: string
}

export interface RPCServiceInfo {
  name: string
  summary: string
  description: string
  methods: MethodInfo[]
}

export interface RegionList {
  items: RegionListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface RegionListItem {
  id: number
  name: string
  title: string
}

export interface SectorList {
  items: SectorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface SectorListItem {
  id: number
  name: string
  title: string
}

export interface SkippedInvestorFundItem {
  rowIndex: number
  fundName: string
}

export interface SubsectorList {
  items: SubsectorListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface SubsectorListItem {
  id: number
  name: string
  title: string
  sector: string
}

export interface SuggestAddressResponse {
  googlePlaceId: string
  description: string
}

export interface SuggestedTag {
  id: number
  name: string
  assetCount: number
}

export interface Tag {
  readonly id: number
  name: string
  createdAt: string
  updatedAt: string
}

export interface TagList {
  items: TagListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface TagListItem {
  id: number
  name: string
  assetCount: number
  createdAt: string
  updatedAt: string
}

export interface TimeEntry {
  readonly id: number
  readonly createdAt: string
  assetId: number
  userId: number
  date: string
  minutes: number
  type: TimeEntryType
}

export interface TimeEntryList {
  items: TimeEntryListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface TimeEntryListItem {
  readonly id: number
  readonly createdAt: string
  assetId: number
  userId: number
  date: string
  minutes: number
  type: TimeEntryType
  fullName: string
}

export interface TypeInfo {
  name: string
  summary: string
  description: string
  properties: PropertyInfo[]
}

export interface UserAccount {
  readonly id: number
  readonly status: Shared.UserStatus
  customerId: number
  username: string
  firstName: string
  lastName: string
  email: string
  currency: string
  unitSystem: Shared.UserUnitSystem
  role: Shared.UserRole
  recommendRegions: string[]
  recommendSubsectors: string[]
  readonly invitationLink: string
  readonly invitationExpired: boolean
  readonly invitationSentAt: string
  isSsoUser: boolean
  emailSourcingUpdates: boolean
  readonly emailSourcingUpdatesLastSentAt: string | null
  emailResearchUpdates: boolean
  readonly emailResearchUpdatesLastSentAt: string | null
  emailAssetUpdatesNotification: boolean
  readonly emailAssetUpdatesNotificationLastSentAt: string | null
  readonly createdAt: string
  readonly lastAccessAt: string | null
  readonly onboardedAt: string | null
  readonly hasSharedBookmarks: boolean
  readonly lastFailedLogin: string | null
  readonly failedLoginCount: number
  readonly deactivatedAt: string | null
  readonly deactivatedReason: string
  isApiUser: boolean
}

export interface UserList {
  items: UserListItem[]
  counts: ListCounts
  args: ListArgs
}

export interface UserListItem {
  id: number
  status: Shared.UserStatus
  username: string
  currency: string
  unitSystem: Shared.UserUnitSystem
  customerId: number
  customerName: string
  role: Shared.UserRole
  firstName: string
  lastName: string
  email: string
  lastAccessAt: string | null
  onboardedAt: string | null
  deactivatedAt: string | null
  deactivatedReason: string
}

export interface Value {
  name: string
  desc: string
  value: any
}

export interface AddAssetWorkflowAssigneesParams {
  ids: number[]
  userIds: number[]
}

export interface AddAssetWorkflowProjectsParams {
  ids: number[]
  projectIds: number[]
}

export interface CreateAssetWorkflowAssigneeParams {
  id: number
  userId: number
}

export interface CreateAssetWorkflowProjectParams {
  id: number
  projectId: number
}

export interface CreateNoteParams {
  assetId: number | null
  dealId: number | null
  content: string
  tab: string
}

export interface CreateProjectParams {
  partial: object
}

export interface CreateTimeEntryParams {
  partial: object
}

export interface DeleteAssetWorkflowAssigneesParams {
  ids: number[]
}

export interface DeleteAssetWorkflowProjectsParams {
  ids: number[]
}

export interface DeleteNoteParams {
  id: number
}

export interface DeleteProjectParams {
  id: number
}

export interface DeleteTimeEntryParams {
  id: number
}

export interface DeleteWorkflowAssigneeFromAssetParams {
  id: number
  userId: number
}

export interface DeleteWorkflowProjectFromAssetParams {
  id: number
  projectId: number
}

export interface GetProjectParams {
  id: number
}

export interface ListNotesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListProjectsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListTimeEntriesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface UpdateAssetWorkflowParams {
  id: number
  partial: object
}

export interface UpdateAssetWorkflowsParams {
  ids: number[]
  partial: object
}

export interface UpdateNoteParams {
  id: number
  partial: object
}

export interface UpdateProjectParams {
  id: number
  partial: object
}

export interface CreateApiKeyParams {
  partial: object
}

export interface CreateCustomerParams {
  partial: object
}

export interface CreateUserParams {
  partial: object
}

export interface DeactivateCustomerParams {
  id: number
  sendEmail: boolean
}

export interface DeleteApiKeyParams {
  id: number
}

export interface DeleteCustomerParams {
  id: number
}

export interface DeleteUserParams {
  id: number
}

export interface GetApiKeyParams {
  id: number
}

export interface GetCustomerParams {
  id: number
}

export interface GetCustomerUsageReportParams {
  customerIds: number[]
  type: CustomerUsageReportType
  format: ReportFormat
  to: string | null
  from: string | null
}

export interface GetUserParams {
  id: number
}

export interface GetUserInvitationLinkParams {
  userId: number
}

export interface ListApiKeysParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListCustomersParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListUsersParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ReactivateCustomerParams {
  id: number
  sendEmail: boolean
}

export interface ResetUserPasswordParams {
  id: number
  password: string
}

export interface SendUserInvitationParams {
  id: number
}

export interface SetUserStatusParams {
  id: number
  status: Shared.UserStatus
}

export interface UpdateApiKeyParams {
  id: number
  partial: object
}

export interface UpdateCustomerParams {
  id: number
  partial: object
}

export interface UpdateUserParams {
  id: number
  partial: object
}

export interface ArchiveAssetParams {
  id: number
}

export interface CreateAddressParams {
  partial: object
}

export interface CreateAdvisorParams {
  partial: object
}

export interface CreateArticleParams {
  partial: object
}

export interface CreateAutomatedAssetParams {
  linkedinUrl: string
}

export interface CreateConferenceParams {
  partial: object
}

export interface CreateConferenceEditionParams {
  partial: object
}

export interface CreateConferenceExhibitorParams {
  partial: object
}

export interface CreateConferenceVenueParams {
  partial: object
}

export interface CreateCuratedAssetParams {
  profileType: Shared.AssetProfileType
  name: string
  headquarters: string
  webUrl: string | null
  priority: boolean
  dueDate: string | null
  assigneeIds: number[]
  projectIds: number[]
}

export interface CreateDealParams {
  partial: object
}

export interface CreateIndustryParams {
  partial: object
}

export interface CreateInvestorParams {
  partial: object
}

export interface CreateLegalEntityParams {
  partial: object
}

export interface CreatePersonParams {
  partial: object
}

export interface CreateTagByNameParams {
  name: string
}

export interface DeleteAdvisorParams {
  id: number
}

export interface DeleteArticleParams {
  id: number
}

export interface DeleteAssetParams {
  id: number
}

export interface DeleteConferenceParams {
  id: number
}

export interface DeleteConferenceEditionParams {
  id: number
}

export interface DeleteConferenceExhibitorParams {
  id: number
}

export interface DeleteConferenceVenueParams {
  id: number
}

export interface DeleteDealParams {
  id: number
}

export interface DeleteIndustryParams {
  id: number
}

export interface DeleteInvestorParams {
  id: number
}

export interface DeleteLegalEntityParams {
  id: number
}

export interface DeletePersonParams {
  id: number
}

export interface DeleteTagParams {
  id: number
}

export interface DownloadAnnualReportsParams {
  legalEntityIds: number[]
  sinceYear: number
}

export interface ExportAssetFinancialsParams {
  id: number
}

export interface GetAddressByPlaceIdParams {
  countryCode: string
  googlePlaceId: string
}

export interface GetAdvisorParams {
  id: number
}

export interface GetArticleParams {
  id: number
}

export interface GetAssetParams {
  id: number
}

export interface GetAssetExcelFilesByAssetParams {
  id: number
}

export interface GetConferenceParams {
  id: number
}

export interface GetConferenceEditionParams {
  id: number
}

export interface GetConferenceExhibitorParams {
  id: number
}

export interface GetConferenceVenueParams {
  id: number
}

export interface GetDealParams {
  id: number
}

export interface GetFileInfoParams {
  id: number
}

export interface GetIndustryParams {
  id: number
}

export interface GetInvestorParams {
  id: number
}

export interface GetLegalEntityParams {
  id: number
}

export interface GetOrCreateTagByNameParams {
  tagName: string
}

export interface GetPersonParams {
  id: number
}

export interface ImportConferenceEditionExhibitorsParams {
  id: number
  fileId: number
}

export interface ImportInvestorFundsParams {
  fileId: number
}

export interface LinkAssetToExcelFileParams {
  id: number
  excelFileId: number
  importFinancials: boolean
  importCharts: boolean
  comment: string
}

export interface ListAdvisorDealsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAdvisorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAnnualReportsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListArticlesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAssetManagersParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAssetsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListAssociatedTagsParams {
  tagIds: number[]
  limit: number
  assets: boolean
}

export interface ListCompetitorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListConferenceEditionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListConferenceExhibitorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListConferenceVenuesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListConferencesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListCurrenciesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListDealsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListIndustriesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListIndustryAssetsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorFundsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorStrategiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListInvestorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListLegalEntitiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListListedSecuritiesParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListPersonsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListRegionsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListSectorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListSubsectorsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface ListTagsParams {
  search: string
  filter: Filter[]
  sort: string[]
  limit: number
  page: number
}

export interface PublishAdvisorParams {
  id: number
}

export interface PublishArticleParams {
  id: number
}

export interface PublishAssetProfileParams {
  id: number
}

export interface PublishConferenceEditionParams {
  id: number
}

export interface PublishDealParams {
  id: number
}

export interface PublishIndustryParams {
  id: number
}

export interface PublishInvestorParams {
  id: number
}

export interface PublishPersonParams {
  id: number
}

export interface SuggestAddressParams {
  countryCode: string
  input: string
  sessionToken: string
}

export interface SuggestListedSecuritiesParams {
  searchQuery: string
}

export interface SuggestTagsParams {
  text: string
  limit: number
}

export interface UnarchiveAssetParams {
  id: number
}

export interface UnlinkAssetFromExcelFileParams {
  id: number
}

export interface UnpublishAdvisorParams {
  id: number
}

export interface UnpublishArticleParams {
  id: number
}

export interface UnpublishAssetProfileParams {
  id: number
}

export interface UnpublishConferenceEditionParams {
  id: number
}

export interface UnpublishDealParams {
  id: number
}

export interface UnpublishIndustryParams {
  id: number
}

export interface UnpublishInvestorParams {
  id: number
}

export interface UnpublishPersonParams {
  id: number
}

export interface UpdateAddressParams {
  id: number
  partial: object
}

export interface UpdateAdvisorParams {
  id: number
  partial: object
}

export interface UpdateArticleParams {
  id: number
  partial: object
}

export interface UpdateAssetParams {
  id: number
  partial: object
}

export interface UpdateAssetParentParams {
  assetId: number
  parentId: number | null
}

export interface UpdateConferenceParams {
  id: number
  partial: object
}

export interface UpdateConferenceEditionParams {
  id: number
  partial: object
}

export interface UpdateConferenceExhibitorParams {
  id: number
  partial: object
}

export interface UpdateConferenceVenueParams {
  id: number
  partial: object
}

export interface UpdateDealParams {
  id: number
  partial: object
}

export interface UpdateIndustryParams {
  id: number
  partial: object
}

export interface UpdateInvestorParams {
  id: number
  partial: object
}

export interface UpdateLegalEntityParams {
  id: number
  partial: object
}

export interface UpdatePersonParams {
  id: number
  partial: object
}

export interface UpdateTagParams {
  id: number
  partial: object
}

export interface ValidateAdvisorParams {
  id: number
}

export interface ValidateArticleParams {
  id: number
}

export interface ValidateAssetProfileParams {
  id: number
}

export interface ValidateConferenceEditionParams {
  id: number
}

export interface ValidateDealParams {
  id: number
}

export interface ValidateIndustryParams {
  id: number
}

export interface ValidateInvestorParams {
  id: number
}

export interface ValidateLegalEntityParams {
  id: number
}

export interface ValidatePersonParams {
  id: number
}

export interface PreviewBookmarkUpdatesEmailParams {
  userId: number
  since: string | null
}

export interface PreviewIndustryEmailParams {
  userId: number
  industryId: number
}

export interface PreviewSourcingUpdateParams {
  userId: number
  since: string | null
}

export interface SendBookmarkUpdatesEmailParams {
  userId: number
  since: string | null
}

export interface SendIndustryEmailParams {
  userId: number
  industryId: number
}

export interface SendSourcingUpdateParams {
  userId: number
  since: string | null
}

export interface ErrorParams {
  code: number
  message: string
  data: any
}

export const cms = {
  addAssetWorkflowAssignees: (params: AddAssetWorkflowAssigneesParams) =>
    Jsonrpc.createMethod('cms.addAssetWorkflowAssignees', params),
  addAssetWorkflowProjects: (params: AddAssetWorkflowProjectsParams) =>
    Jsonrpc.createMethod('cms.addAssetWorkflowProjects', params),
  /**
   * Result: AssetWorkflowAssignee
   */
  createAssetWorkflowAssignee: (params: CreateAssetWorkflowAssigneeParams) =>
    Jsonrpc.createMethod('cms.createAssetWorkflowAssignee', params),
  /**
   * Result: AssetWorkflowProject
   */
  createAssetWorkflowProject: (params: CreateAssetWorkflowProjectParams) =>
    Jsonrpc.createMethod('cms.createAssetWorkflowProject', params),
  /**
   * Result: Note
   */
  createNote: (params: CreateNoteParams) => Jsonrpc.createMethod('cms.createNote', params),
  /**
   * Result: Project
   */
  createProject: (params: CreateProjectParams) => Jsonrpc.createMethod('cms.createProject', params),
  /**
   * Result: TimeEntry
   */
  createTimeEntry: (params: CreateTimeEntryParams) =>
    Jsonrpc.createMethod('cms.createTimeEntry', params),
  deleteAssetWorkflowAssignees: (params: DeleteAssetWorkflowAssigneesParams) =>
    Jsonrpc.createMethod('cms.deleteAssetWorkflowAssignees', params),
  deleteAssetWorkflowProjects: (params: DeleteAssetWorkflowProjectsParams) =>
    Jsonrpc.createMethod('cms.deleteAssetWorkflowProjects', params),
  deleteNote: (params: DeleteNoteParams) => Jsonrpc.createMethod('cms.deleteNote', params),
  deleteProject: (params: DeleteProjectParams) => Jsonrpc.createMethod('cms.deleteProject', params),
  deleteTimeEntry: (params: DeleteTimeEntryParams) =>
    Jsonrpc.createMethod('cms.deleteTimeEntry', params),
  deleteWorkflowAssigneeFromAsset: (params: DeleteWorkflowAssigneeFromAssetParams) =>
    Jsonrpc.createMethod('cms.deleteWorkflowAssigneeFromAsset', params),
  deleteWorkflowProjectFromAsset: (params: DeleteWorkflowProjectFromAssetParams) =>
    Jsonrpc.createMethod('cms.deleteWorkflowProjectFromAsset', params),
  /**
   * Result: Project
   */
  getProject: (params: GetProjectParams) => Jsonrpc.createMethod('cms.getProject', params),
  /**
   * Result: NoteList
   */
  listNotes: (params: ListNotesParams) => Jsonrpc.createMethod('cms.listNotes', params),
  /**
   * Result: ProjectList
   */
  listProjects: (params: ListProjectsParams) => Jsonrpc.createMethod('cms.listProjects', params),
  /**
   * Result: TimeEntryList
   */
  listTimeEntries: (params: ListTimeEntriesParams) =>
    Jsonrpc.createMethod('cms.listTimeEntries', params),
  updateAssetWorkflow: (params: UpdateAssetWorkflowParams) =>
    Jsonrpc.createMethod('cms.updateAssetWorkflow', params),
  updateAssetWorkflows: (params: UpdateAssetWorkflowsParams) =>
    Jsonrpc.createMethod('cms.updateAssetWorkflows', params),
  updateNote: (params: UpdateNoteParams) => Jsonrpc.createMethod('cms.updateNote', params),
  updateProject: (params: UpdateProjectParams) => Jsonrpc.createMethod('cms.updateProject', params),
}

export const customer = {
  /**
   * Result: ApiKey
   */
  createApiKey: (params: CreateApiKeyParams) =>
    Jsonrpc.createMethod('customer.createApiKey', params),
  /**
   * Result: Customer
   */
  createCustomer: (params: CreateCustomerParams) =>
    Jsonrpc.createMethod('customer.createCustomer', params),
  /**
   * Result: UserAccount
   */
  createUser: (params: CreateUserParams) => Jsonrpc.createMethod('customer.createUser', params),
  deactivateCustomer: (params: DeactivateCustomerParams) =>
    Jsonrpc.createMethod('customer.deactivateCustomer', params),
  deleteApiKey: (params: DeleteApiKeyParams) =>
    Jsonrpc.createMethod('customer.deleteApiKey', params),
  deleteCustomer: (params: DeleteCustomerParams) =>
    Jsonrpc.createMethod('customer.deleteCustomer', params),
  deleteUser: (params: DeleteUserParams) => Jsonrpc.createMethod('customer.deleteUser', params),
  /**
   * Result: ApiKey
   */
  getApiKey: (params: GetApiKeyParams) => Jsonrpc.createMethod('customer.getApiKey', params),
  /**
   * Result: Customer
   */
  getCustomer: (params: GetCustomerParams) => Jsonrpc.createMethod('customer.getCustomer', params),
  /**
   * Result: string
   */
  getCustomerUsageReport: (params: GetCustomerUsageReportParams) =>
    Jsonrpc.createMethod('customer.getCustomerUsageReport', params),
  /**
   * Result: UserAccount
   */
  getUser: (params: GetUserParams) => Jsonrpc.createMethod('customer.getUser', params),
  /**
   * Result: string
   */
  getUserInvitationLink: (params: GetUserInvitationLinkParams) =>
    Jsonrpc.createMethod('customer.getUserInvitationLink', params),
  /**
   * Result: ApiKeyList
   */
  listApiKeys: (params: ListApiKeysParams) => Jsonrpc.createMethod('customer.listApiKeys', params),
  /**
   * Result: CustomerList
   */
  listCustomers: (params: ListCustomersParams) =>
    Jsonrpc.createMethod('customer.listCustomers', params),
  /**
   * Result: UserList
   */
  listUsers: (params: ListUsersParams) => Jsonrpc.createMethod('customer.listUsers', params),
  reactivateCustomer: (params: ReactivateCustomerParams) =>
    Jsonrpc.createMethod('customer.reactivateCustomer', params),
  resetUserPassword: (params: ResetUserPasswordParams) =>
    Jsonrpc.createMethod('customer.resetUserPassword', params),
  sendUserInvitation: (params: SendUserInvitationParams) =>
    Jsonrpc.createMethod('customer.sendUserInvitation', params),
  /**
   * Updates the user's account status. If status is equal to the current status, this method has no effect. Note that the user will receive an email with if status is changed to deactivated/active.
   */
  setUserStatus: (params: SetUserStatusParams) =>
    Jsonrpc.createMethod('customer.setUserStatus', params),
  updateApiKey: (params: UpdateApiKeyParams) =>
    Jsonrpc.createMethod('customer.updateApiKey', params),
  updateCustomer: (params: UpdateCustomerParams) =>
    Jsonrpc.createMethod('customer.updateCustomer', params),
  /**
   * Updates the user account settings. To modify a user's account status, user customer.setUserStatus().
   */
  updateUser: (params: UpdateUserParams) => Jsonrpc.createMethod('customer.updateUser', params),
}

export const data = {
  archiveAsset: (params: ArchiveAssetParams) => Jsonrpc.createMethod('data.archiveAsset', params),
  /**
   * Result: Address
   */
  createAddress: (params: CreateAddressParams) =>
    Jsonrpc.createMethod('data.createAddress', params),
  /**
   * Result: Advisor
   */
  createAdvisor: (params: CreateAdvisorParams) =>
    Jsonrpc.createMethod('data.createAdvisor', params),
  /**
   * Result: Article
   */
  createArticle: (params: CreateArticleParams) =>
    Jsonrpc.createMethod('data.createArticle', params),
  /**
   * Result: number(int)
   */
  createAutomatedAsset: (params: CreateAutomatedAssetParams) =>
    Jsonrpc.createMethod('data.createAutomatedAsset', params),
  /**
   * Result: Conference
   */
  createConference: (params: CreateConferenceParams) =>
    Jsonrpc.createMethod('data.createConference', params),
  /**
   * Result: ConferenceEdition
   */
  createConferenceEdition: (params: CreateConferenceEditionParams) =>
    Jsonrpc.createMethod('data.createConferenceEdition', params),
  /**
   * Result: ConferenceExhibitor
   */
  createConferenceExhibitor: (params: CreateConferenceExhibitorParams) =>
    Jsonrpc.createMethod('data.createConferenceExhibitor', params),
  /**
   * Result: ConferenceVenue
   */
  createConferenceVenue: (params: CreateConferenceVenueParams) =>
    Jsonrpc.createMethod('data.createConferenceVenue', params),
  /**
   * Result: number(int)
   */
  createCuratedAsset: (params: CreateCuratedAssetParams) =>
    Jsonrpc.createMethod('data.createCuratedAsset', params),
  /**
   * Result: Deal
   */
  createDeal: (params: CreateDealParams) => Jsonrpc.createMethod('data.createDeal', params),
  /**
   * Result: Industry
   */
  createIndustry: (params: CreateIndustryParams) =>
    Jsonrpc.createMethod('data.createIndustry', params),
  /**
   * Result: Investor
   */
  createInvestor: (params: CreateInvestorParams) =>
    Jsonrpc.createMethod('data.createInvestor', params),
  /**
   * Result: LegalEntity
   */
  createLegalEntity: (params: CreateLegalEntityParams) =>
    Jsonrpc.createMethod('data.createLegalEntity', params),
  /**
   * Result: Person
   */
  createPerson: (params: CreatePersonParams) => Jsonrpc.createMethod('data.createPerson', params),
  /**
   * Result: Tag
   */
  createTagByName: (params: CreateTagByNameParams) =>
    Jsonrpc.createMethod('data.createTagByName', params),
  deleteAdvisor: (params: DeleteAdvisorParams) =>
    Jsonrpc.createMethod('data.deleteAdvisor', params),
  deleteArticle: (params: DeleteArticleParams) =>
    Jsonrpc.createMethod('data.deleteArticle', params),
  deleteAsset: (params: DeleteAssetParams) => Jsonrpc.createMethod('data.deleteAsset', params),
  deleteConference: (params: DeleteConferenceParams) =>
    Jsonrpc.createMethod('data.deleteConference', params),
  deleteConferenceEdition: (params: DeleteConferenceEditionParams) =>
    Jsonrpc.createMethod('data.deleteConferenceEdition', params),
  deleteConferenceExhibitor: (params: DeleteConferenceExhibitorParams) =>
    Jsonrpc.createMethod('data.deleteConferenceExhibitor', params),
  deleteConferenceVenue: (params: DeleteConferenceVenueParams) =>
    Jsonrpc.createMethod('data.deleteConferenceVenue', params),
  deleteDeal: (params: DeleteDealParams) => Jsonrpc.createMethod('data.deleteDeal', params),
  deleteIndustry: (params: DeleteIndustryParams) =>
    Jsonrpc.createMethod('data.deleteIndustry', params),
  deleteInvestor: (params: DeleteInvestorParams) =>
    Jsonrpc.createMethod('data.deleteInvestor', params),
  deleteLegalEntity: (params: DeleteLegalEntityParams) =>
    Jsonrpc.createMethod('data.deleteLegalEntity', params),
  deletePerson: (params: DeletePersonParams) => Jsonrpc.createMethod('data.deletePerson', params),
  deleteTag: (params: DeleteTagParams) => Jsonrpc.createMethod('data.deleteTag', params),
  /**
   * Result: DownloadAnnualReportsResult
   */
  downloadAnnualReports: (params: DownloadAnnualReportsParams) =>
    Jsonrpc.createMethod('data.downloadAnnualReports', params),
  /**
   * Result: string
   */
  exportAssetFinancials: (params: ExportAssetFinancialsParams) =>
    Jsonrpc.createMethod('data.exportAssetFinancials', params),
  /**
   * Result: Address
   */
  getAddressByPlaceId: (params: GetAddressByPlaceIdParams) =>
    Jsonrpc.createMethod('data.getAddressByPlaceId', params),
  /**
   * Result: Advisor
   */
  getAdvisor: (params: GetAdvisorParams) => Jsonrpc.createMethod('data.getAdvisor', params),
  /**
   * Result: Article
   */
  getArticle: (params: GetArticleParams) => Jsonrpc.createMethod('data.getArticle', params),
  /**
   * Result: Asset
   */
  getAsset: (params: GetAssetParams) => Jsonrpc.createMethod('data.getAsset', params),
  /**
   * Result: AssetExcelFileList
   */
  getAssetExcelFilesByAsset: (params: GetAssetExcelFilesByAssetParams) =>
    Jsonrpc.createMethod('data.getAssetExcelFilesByAsset', params),
  /**
   * Result: Conference
   */
  getConference: (params: GetConferenceParams) =>
    Jsonrpc.createMethod('data.getConference', params),
  /**
   * Result: ConferenceEdition
   */
  getConferenceEdition: (params: GetConferenceEditionParams) =>
    Jsonrpc.createMethod('data.getConferenceEdition', params),
  /**
   * Result: ConferenceExhibitor
   */
  getConferenceExhibitor: (params: GetConferenceExhibitorParams) =>
    Jsonrpc.createMethod('data.getConferenceExhibitor', params),
  /**
   * Result: ConferenceVenue
   */
  getConferenceVenue: (params: GetConferenceVenueParams) =>
    Jsonrpc.createMethod('data.getConferenceVenue', params),
  /**
   * Result: Deal
   */
  getDeal: (params: GetDealParams) => Jsonrpc.createMethod('data.getDeal', params),
  /**
   * Result: FileInfo
   */
  getFileInfo: (params: GetFileInfoParams) => Jsonrpc.createMethod('data.getFileInfo', params),
  /**
   * Result: Industry
   */
  getIndustry: (params: GetIndustryParams) => Jsonrpc.createMethod('data.getIndustry', params),
  /**
   * Result: Investor
   */
  getInvestor: (params: GetInvestorParams) => Jsonrpc.createMethod('data.getInvestor', params),
  /**
   * Result: LegalEntity
   */
  getLegalEntity: (params: GetLegalEntityParams) =>
    Jsonrpc.createMethod('data.getLegalEntity', params),
  /**
   * Result: Tag
   */
  getOrCreateTagByName: (params: GetOrCreateTagByNameParams) =>
    Jsonrpc.createMethod('data.getOrCreateTagByName', params),
  /**
   * Result: Person
   */
  getPerson: (params: GetPersonParams) => Jsonrpc.createMethod('data.getPerson', params),
  importConferenceEditionExhibitors: (params: ImportConferenceEditionExhibitorsParams) =>
    Jsonrpc.createMethod('data.importConferenceEditionExhibitors', params),
  /**
   * Result: ImportInvestorFundsResult
   */
  importInvestorFunds: (params: ImportInvestorFundsParams) =>
    Jsonrpc.createMethod('data.importInvestorFunds', params),
  linkAssetToExcelFile: (params: LinkAssetToExcelFileParams) =>
    Jsonrpc.createMethod('data.linkAssetToExcelFile', params),
  /**
   * Result: AdvisorDealList
   */
  listAdvisorDeals: (params: ListAdvisorDealsParams) =>
    Jsonrpc.createMethod('data.listAdvisorDeals', params),
  /**
   * Result: AdvisorList
   */
  listAdvisors: (params: ListAdvisorsParams) => Jsonrpc.createMethod('data.listAdvisors', params),
  /**
   * Result: AnnualReportList
   */
  listAnnualReports: (params: ListAnnualReportsParams) =>
    Jsonrpc.createMethod('data.listAnnualReports', params),
  /**
   * Result: ArticleList
   */
  listArticles: (params: ListArticlesParams) => Jsonrpc.createMethod('data.listArticles', params),
  /**
   * Result: AssetManagerList
   */
  listAssetManagers: (params: ListAssetManagersParams) =>
    Jsonrpc.createMethod('data.listAssetManagers', params),
  /**
   * Result: AssetList
   */
  listAssets: (params: ListAssetsParams) => Jsonrpc.createMethod('data.listAssets', params),
  /**
   * Result: array(AssociatedTag)
   */
  listAssociatedTags: (params: ListAssociatedTagsParams) =>
    Jsonrpc.createMethod('data.listAssociatedTags', params),
  /**
   * Result: CompetitorList
   */
  listCompetitors: (params: ListCompetitorsParams) =>
    Jsonrpc.createMethod('data.listCompetitors', params),
  /**
   * Result: ConferenceEditionList
   */
  listConferenceEditions: (params: ListConferenceEditionsParams) =>
    Jsonrpc.createMethod('data.listConferenceEditions', params),
  /**
   * Result: ConferenceExhibitorList
   */
  listConferenceExhibitors: (params: ListConferenceExhibitorsParams) =>
    Jsonrpc.createMethod('data.listConferenceExhibitors', params),
  /**
   * Result: ConferenceVenueList
   */
  listConferenceVenues: (params: ListConferenceVenuesParams) =>
    Jsonrpc.createMethod('data.listConferenceVenues', params),
  /**
   * Result: ConferenceList
   */
  listConferences: (params: ListConferencesParams) =>
    Jsonrpc.createMethod('data.listConferences', params),
  /**
   * Result: CurrencyList
   */
  listCurrencies: (params: ListCurrenciesParams) =>
    Jsonrpc.createMethod('data.listCurrencies', params),
  /**
   * Result: DealList
   */
  listDeals: (params: ListDealsParams) => Jsonrpc.createMethod('data.listDeals', params),
  /**
   * Result: IndustryList
   */
  listIndustries: (params: ListIndustriesParams) =>
    Jsonrpc.createMethod('data.listIndustries', params),
  /**
   * Result: IndustryAssetList
   */
  listIndustryAssets: (params: ListIndustryAssetsParams) =>
    Jsonrpc.createMethod('data.listIndustryAssets', params),
  /**
   * Result: InvestorFundList
   */
  listInvestorFunds: (params: ListInvestorFundsParams) =>
    Jsonrpc.createMethod('data.listInvestorFunds', params),
  /**
   * Result: InvestorStrategyList
   */
  listInvestorStrategies: (params: ListInvestorStrategiesParams) =>
    Jsonrpc.createMethod('data.listInvestorStrategies', params),
  /**
   * Result: InvestorList
   */
  listInvestors: (params: ListInvestorsParams) =>
    Jsonrpc.createMethod('data.listInvestors', params),
  /**
   * Result: LegalEntityList
   */
  listLegalEntities: (params: ListLegalEntitiesParams) =>
    Jsonrpc.createMethod('data.listLegalEntities', params),
  /**
   * Result: ListedSecurityList
   */
  listListedSecurities: (params: ListListedSecuritiesParams) =>
    Jsonrpc.createMethod('data.listListedSecurities', params),
  /**
   * Result: PersonList
   */
  listPersons: (params: ListPersonsParams) => Jsonrpc.createMethod('data.listPersons', params),
  /**
   * Result: RegionList
   */
  listRegions: (params: ListRegionsParams) => Jsonrpc.createMethod('data.listRegions', params),
  /**
   * Result: SectorList
   */
  listSectors: (params: ListSectorsParams) => Jsonrpc.createMethod('data.listSectors', params),
  /**
   * Result: SubsectorList
   */
  listSubsectors: (params: ListSubsectorsParams) =>
    Jsonrpc.createMethod('data.listSubsectors', params),
  /**
   * Result: TagList
   */
  listTags: (params: ListTagsParams) => Jsonrpc.createMethod('data.listTags', params),
  publishAdvisor: (params: PublishAdvisorParams) =>
    Jsonrpc.createMethod('data.publishAdvisor', params),
  publishArticle: (params: PublishArticleParams) =>
    Jsonrpc.createMethod('data.publishArticle', params),
  publishAssetProfile: (params: PublishAssetProfileParams) =>
    Jsonrpc.createMethod('data.publishAssetProfile', params),
  publishConferenceEdition: (params: PublishConferenceEditionParams) =>
    Jsonrpc.createMethod('data.publishConferenceEdition', params),
  publishDeal: (params: PublishDealParams) => Jsonrpc.createMethod('data.publishDeal', params),
  publishIndustry: (params: PublishIndustryParams) =>
    Jsonrpc.createMethod('data.publishIndustry', params),
  publishInvestor: (params: PublishInvestorParams) =>
    Jsonrpc.createMethod('data.publishInvestor', params),
  publishPerson: (params: PublishPersonParams) =>
    Jsonrpc.createMethod('data.publishPerson', params),
  /**
   * Result: array(SuggestAddressResponse)
   */
  suggestAddress: (params: SuggestAddressParams) =>
    Jsonrpc.createMethod('data.suggestAddress', params),
  /**
   * Result: array(ListedSecuritySuggestion)
   */
  suggestListedSecurities: (params: SuggestListedSecuritiesParams) =>
    Jsonrpc.createMethod('data.suggestListedSecurities', params),
  /**
   * Result: array(SuggestedTag)
   */
  suggestTags: (params: SuggestTagsParams) => Jsonrpc.createMethod('data.suggestTags', params),
  unarchiveAsset: (params: UnarchiveAssetParams) =>
    Jsonrpc.createMethod('data.unarchiveAsset', params),
  unlinkAssetFromExcelFile: (params: UnlinkAssetFromExcelFileParams) =>
    Jsonrpc.createMethod('data.unlinkAssetFromExcelFile', params),
  unpublishAdvisor: (params: UnpublishAdvisorParams) =>
    Jsonrpc.createMethod('data.unpublishAdvisor', params),
  unpublishArticle: (params: UnpublishArticleParams) =>
    Jsonrpc.createMethod('data.unpublishArticle', params),
  unpublishAssetProfile: (params: UnpublishAssetProfileParams) =>
    Jsonrpc.createMethod('data.unpublishAssetProfile', params),
  unpublishConferenceEdition: (params: UnpublishConferenceEditionParams) =>
    Jsonrpc.createMethod('data.unpublishConferenceEdition', params),
  unpublishDeal: (params: UnpublishDealParams) =>
    Jsonrpc.createMethod('data.unpublishDeal', params),
  unpublishIndustry: (params: UnpublishIndustryParams) =>
    Jsonrpc.createMethod('data.unpublishIndustry', params),
  unpublishInvestor: (params: UnpublishInvestorParams) =>
    Jsonrpc.createMethod('data.unpublishInvestor', params),
  unpublishPerson: (params: UnpublishPersonParams) =>
    Jsonrpc.createMethod('data.unpublishPerson', params),
  /**
   * Result: Address
   */
  updateAddress: (params: UpdateAddressParams) =>
    Jsonrpc.createMethod('data.updateAddress', params),
  updateAdvisor: (params: UpdateAdvisorParams) =>
    Jsonrpc.createMethod('data.updateAdvisor', params),
  updateArticle: (params: UpdateArticleParams) =>
    Jsonrpc.createMethod('data.updateArticle', params),
  updateAsset: (params: UpdateAssetParams) => Jsonrpc.createMethod('data.updateAsset', params),
  updateAssetParent: (params: UpdateAssetParentParams) =>
    Jsonrpc.createMethod('data.updateAssetParent', params),
  updateConference: (params: UpdateConferenceParams) =>
    Jsonrpc.createMethod('data.updateConference', params),
  updateConferenceEdition: (params: UpdateConferenceEditionParams) =>
    Jsonrpc.createMethod('data.updateConferenceEdition', params),
  updateConferenceExhibitor: (params: UpdateConferenceExhibitorParams) =>
    Jsonrpc.createMethod('data.updateConferenceExhibitor', params),
  updateConferenceVenue: (params: UpdateConferenceVenueParams) =>
    Jsonrpc.createMethod('data.updateConferenceVenue', params),
  updateDeal: (params: UpdateDealParams) => Jsonrpc.createMethod('data.updateDeal', params),
  updateIndustry: (params: UpdateIndustryParams) =>
    Jsonrpc.createMethod('data.updateIndustry', params),
  updateInvestor: (params: UpdateInvestorParams) =>
    Jsonrpc.createMethod('data.updateInvestor', params),
  updateLegalEntity: (params: UpdateLegalEntityParams) =>
    Jsonrpc.createMethod('data.updateLegalEntity', params),
  updatePerson: (params: UpdatePersonParams) => Jsonrpc.createMethod('data.updatePerson', params),
  updateTag: (params: UpdateTagParams) => Jsonrpc.createMethod('data.updateTag', params),
  /**
   * Result: array(Issue)
   */
  validateAdvisor: (params: ValidateAdvisorParams) =>
    Jsonrpc.createMethod('data.validateAdvisor', params),
  /**
   * Result: array(Issue)
   */
  validateArticle: (params: ValidateArticleParams) =>
    Jsonrpc.createMethod('data.validateArticle', params),
  /**
   * Result: array(Issue)
   */
  validateAssetProfile: (params: ValidateAssetProfileParams) =>
    Jsonrpc.createMethod('data.validateAssetProfile', params),
  /**
   * Result: array(Issue)
   */
  validateConferenceEdition: (params: ValidateConferenceEditionParams) =>
    Jsonrpc.createMethod('data.validateConferenceEdition', params),
  /**
   * Result: array(Issue)
   */
  validateDeal: (params: ValidateDealParams) => Jsonrpc.createMethod('data.validateDeal', params),
  /**
   * Result: array(Issue)
   */
  validateIndustry: (params: ValidateIndustryParams) =>
    Jsonrpc.createMethod('data.validateIndustry', params),
  /**
   * Result: array(Issue)
   */
  validateInvestor: (params: ValidateInvestorParams) =>
    Jsonrpc.createMethod('data.validateInvestor', params),
  /**
   * Result: array(Issue)
   */
  validateLegalEntity: (params: ValidateLegalEntityParams) =>
    Jsonrpc.createMethod('data.validateLegalEntity', params),
  /**
   * Result: array(Issue)
   */
  validatePerson: (params: ValidatePersonParams) =>
    Jsonrpc.createMethod('data.validatePerson', params),
}

export const email = {
  /**
   * Result: Preview
   */
  previewBookmarkUpdatesEmail: (params: PreviewBookmarkUpdatesEmailParams) =>
    Jsonrpc.createMethod('email.previewBookmarkUpdatesEmail', params),
  /**
   * Result: Preview
   */
  previewIndustryEmail: (params: PreviewIndustryEmailParams) =>
    Jsonrpc.createMethod('email.previewIndustryEmail', params),
  /**
   * Result: Preview
   */
  previewSourcingUpdate: (params: PreviewSourcingUpdateParams) =>
    Jsonrpc.createMethod('email.previewSourcingUpdate', params),
  sendBookmarkUpdatesEmail: (params: SendBookmarkUpdatesEmailParams) =>
    Jsonrpc.createMethod('email.sendBookmarkUpdatesEmail', params),
  sendIndustryEmail: (params: SendIndustryEmailParams) =>
    Jsonrpc.createMethod('email.sendIndustryEmail', params),
  sendSourcingUpdate: (params: SendSourcingUpdateParams) =>
    Jsonrpc.createMethod('email.sendSourcingUpdate', params),
}

export const rpc = {
  /**
   * Result: array(EnumInfo)
   */
  enums: () => Jsonrpc.createMethod('rpc.enums'),
  error: (params: ErrorParams) => Jsonrpc.createMethod('rpc.error', params),
  /**
   * Result: array(ErrorInfo)
   */
  errorCodes: () => Jsonrpc.createMethod('rpc.errorCodes'),
  /**
   * Result: string
   */
  ping: () => Jsonrpc.createMethod('rpc.ping'),
  /**
   * Result: array(RPCServiceInfo)
   */
  services: () => Jsonrpc.createMethod('rpc.services'),
  /**
   * Result: array(TypeInfo)
   */
  types: () => Jsonrpc.createMethod('rpc.types'),
}

export type OriginalRpcMethodMap = {
  'cms.addAssetWorkflowAssignees': {
    params: AddAssetWorkflowAssigneesParams
    result: void
  }
  'cms.addAssetWorkflowProjects': {
    params: AddAssetWorkflowProjectsParams
    result: void
  }
  'cms.createAssetWorkflowAssignee': {
    params: CreateAssetWorkflowAssigneeParams
    result: AssetWorkflowAssignee
  }
  'cms.createAssetWorkflowProject': {
    params: CreateAssetWorkflowProjectParams
    result: AssetWorkflowProject
  }
  'cms.createNote': {
    params: CreateNoteParams
    result: Note
  }
  'cms.createProject': {
    params: CreateProjectParams
    result: Project
  }
  'cms.createTimeEntry': {
    params: CreateTimeEntryParams
    result: TimeEntry
  }
  'cms.deleteAssetWorkflowAssignees': {
    params: DeleteAssetWorkflowAssigneesParams
    result: void
  }
  'cms.deleteAssetWorkflowProjects': {
    params: DeleteAssetWorkflowProjectsParams
    result: void
  }
  'cms.deleteNote': {
    params: DeleteNoteParams
    result: void
  }
  'cms.deleteProject': {
    params: DeleteProjectParams
    result: void
  }
  'cms.deleteTimeEntry': {
    params: DeleteTimeEntryParams
    result: void
  }
  'cms.deleteWorkflowAssigneeFromAsset': {
    params: DeleteWorkflowAssigneeFromAssetParams
    result: void
  }
  'cms.deleteWorkflowProjectFromAsset': {
    params: DeleteWorkflowProjectFromAssetParams
    result: void
  }
  'cms.getProject': {
    params: GetProjectParams
    result: Project
  }
  'cms.listNotes': {
    params: ListNotesParams
    result: NoteList
  }
  'cms.listProjects': {
    params: ListProjectsParams
    result: ProjectList
  }
  'cms.listTimeEntries': {
    params: ListTimeEntriesParams
    result: TimeEntryList
  }
  'cms.updateAssetWorkflow': {
    params: UpdateAssetWorkflowParams
    result: void
  }
  'cms.updateAssetWorkflows': {
    params: UpdateAssetWorkflowsParams
    result: void
  }
  'cms.updateNote': {
    params: UpdateNoteParams
    result: void
  }
  'cms.updateProject': {
    params: UpdateProjectParams
    result: void
  }
  'customer.createApiKey': {
    params: CreateApiKeyParams
    result: ApiKey
  }
  'customer.createCustomer': {
    params: CreateCustomerParams
    result: Customer
  }
  'customer.createUser': {
    params: CreateUserParams
    result: UserAccount
  }
  'customer.deactivateCustomer': {
    params: DeactivateCustomerParams
    result: void
  }
  'customer.deleteApiKey': {
    params: DeleteApiKeyParams
    result: void
  }
  'customer.deleteCustomer': {
    params: DeleteCustomerParams
    result: void
  }
  'customer.deleteUser': {
    params: DeleteUserParams
    result: void
  }
  'customer.getApiKey': {
    params: GetApiKeyParams
    result: ApiKey
  }
  'customer.getCustomer': {
    params: GetCustomerParams
    result: Customer
  }
  'customer.getCustomerUsageReport': {
    params: GetCustomerUsageReportParams
    result: string
  }
  'customer.getUser': {
    params: GetUserParams
    result: UserAccount
  }
  'customer.getUserInvitationLink': {
    params: GetUserInvitationLinkParams
    result: string
  }
  'customer.listApiKeys': {
    params: ListApiKeysParams
    result: ApiKeyList
  }
  'customer.listCustomers': {
    params: ListCustomersParams
    result: CustomerList
  }
  'customer.listUsers': {
    params: ListUsersParams
    result: UserList
  }
  'customer.reactivateCustomer': {
    params: ReactivateCustomerParams
    result: void
  }
  'customer.resetUserPassword': {
    params: ResetUserPasswordParams
    result: void
  }
  'customer.sendUserInvitation': {
    params: SendUserInvitationParams
    result: void
  }
  'customer.setUserStatus': {
    params: SetUserStatusParams
    result: void
  }
  'customer.updateApiKey': {
    params: UpdateApiKeyParams
    result: void
  }
  'customer.updateCustomer': {
    params: UpdateCustomerParams
    result: void
  }
  'customer.updateUser': {
    params: UpdateUserParams
    result: void
  }
  'data.archiveAsset': {
    params: ArchiveAssetParams
    result: void
  }
  'data.createAddress': {
    params: CreateAddressParams
    result: Address
  }
  'data.createAdvisor': {
    params: CreateAdvisorParams
    result: Advisor
  }
  'data.createArticle': {
    params: CreateArticleParams
    result: Article
  }
  'data.createAutomatedAsset': {
    params: CreateAutomatedAssetParams
    result: number
  }
  'data.createConference': {
    params: CreateConferenceParams
    result: Conference
  }
  'data.createConferenceEdition': {
    params: CreateConferenceEditionParams
    result: ConferenceEdition
  }
  'data.createConferenceExhibitor': {
    params: CreateConferenceExhibitorParams
    result: ConferenceExhibitor
  }
  'data.createConferenceVenue': {
    params: CreateConferenceVenueParams
    result: ConferenceVenue
  }
  'data.createCuratedAsset': {
    params: CreateCuratedAssetParams
    result: number
  }
  'data.createDeal': {
    params: CreateDealParams
    result: Deal
  }
  'data.createIndustry': {
    params: CreateIndustryParams
    result: Industry
  }
  'data.createInvestor': {
    params: CreateInvestorParams
    result: Investor
  }
  'data.createLegalEntity': {
    params: CreateLegalEntityParams
    result: LegalEntity
  }
  'data.createPerson': {
    params: CreatePersonParams
    result: Person
  }
  'data.createTagByName': {
    params: CreateTagByNameParams
    result: Tag
  }
  'data.deleteAdvisor': {
    params: DeleteAdvisorParams
    result: void
  }
  'data.deleteArticle': {
    params: DeleteArticleParams
    result: void
  }
  'data.deleteAsset': {
    params: DeleteAssetParams
    result: void
  }
  'data.deleteConference': {
    params: DeleteConferenceParams
    result: void
  }
  'data.deleteConferenceEdition': {
    params: DeleteConferenceEditionParams
    result: void
  }
  'data.deleteConferenceExhibitor': {
    params: DeleteConferenceExhibitorParams
    result: void
  }
  'data.deleteConferenceVenue': {
    params: DeleteConferenceVenueParams
    result: void
  }
  'data.deleteDeal': {
    params: DeleteDealParams
    result: void
  }
  'data.deleteIndustry': {
    params: DeleteIndustryParams
    result: void
  }
  'data.deleteInvestor': {
    params: DeleteInvestorParams
    result: void
  }
  'data.deleteLegalEntity': {
    params: DeleteLegalEntityParams
    result: void
  }
  'data.deletePerson': {
    params: DeletePersonParams
    result: void
  }
  'data.deleteTag': {
    params: DeleteTagParams
    result: void
  }
  'data.downloadAnnualReports': {
    params: DownloadAnnualReportsParams
    result: DownloadAnnualReportsResult
  }
  'data.exportAssetFinancials': {
    params: ExportAssetFinancialsParams
    result: string
  }
  'data.getAddressByPlaceId': {
    params: GetAddressByPlaceIdParams
    result: Address
  }
  'data.getAdvisor': {
    params: GetAdvisorParams
    result: Advisor
  }
  'data.getArticle': {
    params: GetArticleParams
    result: Article
  }
  'data.getAsset': {
    params: GetAssetParams
    result: Asset
  }
  'data.getAssetExcelFilesByAsset': {
    params: GetAssetExcelFilesByAssetParams
    result: AssetExcelFileList
  }
  'data.getConference': {
    params: GetConferenceParams
    result: Conference
  }
  'data.getConferenceEdition': {
    params: GetConferenceEditionParams
    result: ConferenceEdition
  }
  'data.getConferenceExhibitor': {
    params: GetConferenceExhibitorParams
    result: ConferenceExhibitor
  }
  'data.getConferenceVenue': {
    params: GetConferenceVenueParams
    result: ConferenceVenue
  }
  'data.getDeal': {
    params: GetDealParams
    result: Deal
  }
  'data.getFileInfo': {
    params: GetFileInfoParams
    result: FileInfo
  }
  'data.getIndustry': {
    params: GetIndustryParams
    result: Industry
  }
  'data.getInvestor': {
    params: GetInvestorParams
    result: Investor
  }
  'data.getLegalEntity': {
    params: GetLegalEntityParams
    result: LegalEntity
  }
  'data.getOrCreateTagByName': {
    params: GetOrCreateTagByNameParams
    result: Tag
  }
  'data.getPerson': {
    params: GetPersonParams
    result: Person
  }
  'data.importConferenceEditionExhibitors': {
    params: ImportConferenceEditionExhibitorsParams
    result: void
  }
  'data.importInvestorFunds': {
    params: ImportInvestorFundsParams
    result: ImportInvestorFundsResult
  }
  'data.linkAssetToExcelFile': {
    params: LinkAssetToExcelFileParams
    result: void
  }
  'data.listAdvisorDeals': {
    params: ListAdvisorDealsParams
    result: AdvisorDealList
  }
  'data.listAdvisors': {
    params: ListAdvisorsParams
    result: AdvisorList
  }
  'data.listAnnualReports': {
    params: ListAnnualReportsParams
    result: AnnualReportList
  }
  'data.listArticles': {
    params: ListArticlesParams
    result: ArticleList
  }
  'data.listAssetManagers': {
    params: ListAssetManagersParams
    result: AssetManagerList
  }
  'data.listAssets': {
    params: ListAssetsParams
    result: AssetList
  }
  'data.listAssociatedTags': {
    params: ListAssociatedTagsParams
    result: AssociatedTag[]
  }
  'data.listCompetitors': {
    params: ListCompetitorsParams
    result: CompetitorList
  }
  'data.listConferenceEditions': {
    params: ListConferenceEditionsParams
    result: ConferenceEditionList
  }
  'data.listConferenceExhibitors': {
    params: ListConferenceExhibitorsParams
    result: ConferenceExhibitorList
  }
  'data.listConferenceVenues': {
    params: ListConferenceVenuesParams
    result: ConferenceVenueList
  }
  'data.listConferences': {
    params: ListConferencesParams
    result: ConferenceList
  }
  'data.listCurrencies': {
    params: ListCurrenciesParams
    result: CurrencyList
  }
  'data.listDeals': {
    params: ListDealsParams
    result: DealList
  }
  'data.listIndustries': {
    params: ListIndustriesParams
    result: IndustryList
  }
  'data.listIndustryAssets': {
    params: ListIndustryAssetsParams
    result: IndustryAssetList
  }
  'data.listInvestorFunds': {
    params: ListInvestorFundsParams
    result: InvestorFundList
  }
  'data.listInvestorStrategies': {
    params: ListInvestorStrategiesParams
    result: InvestorStrategyList
  }
  'data.listInvestors': {
    params: ListInvestorsParams
    result: InvestorList
  }
  'data.listLegalEntities': {
    params: ListLegalEntitiesParams
    result: LegalEntityList
  }
  'data.listListedSecurities': {
    params: ListListedSecuritiesParams
    result: ListedSecurityList
  }
  'data.listPersons': {
    params: ListPersonsParams
    result: PersonList
  }
  'data.listRegions': {
    params: ListRegionsParams
    result: RegionList
  }
  'data.listSectors': {
    params: ListSectorsParams
    result: SectorList
  }
  'data.listSubsectors': {
    params: ListSubsectorsParams
    result: SubsectorList
  }
  'data.listTags': {
    params: ListTagsParams
    result: TagList
  }
  'data.publishAdvisor': {
    params: PublishAdvisorParams
    result: void
  }
  'data.publishArticle': {
    params: PublishArticleParams
    result: void
  }
  'data.publishAssetProfile': {
    params: PublishAssetProfileParams
    result: void
  }
  'data.publishConferenceEdition': {
    params: PublishConferenceEditionParams
    result: void
  }
  'data.publishDeal': {
    params: PublishDealParams
    result: void
  }
  'data.publishIndustry': {
    params: PublishIndustryParams
    result: void
  }
  'data.publishInvestor': {
    params: PublishInvestorParams
    result: void
  }
  'data.publishPerson': {
    params: PublishPersonParams
    result: void
  }
  'data.suggestAddress': {
    params: SuggestAddressParams
    result: SuggestAddressResponse[]
  }
  'data.suggestListedSecurities': {
    params: SuggestListedSecuritiesParams
    result: ListedSecuritySuggestion[]
  }
  'data.suggestTags': {
    params: SuggestTagsParams
    result: SuggestedTag[]
  }
  'data.unarchiveAsset': {
    params: UnarchiveAssetParams
    result: void
  }
  'data.unlinkAssetFromExcelFile': {
    params: UnlinkAssetFromExcelFileParams
    result: void
  }
  'data.unpublishAdvisor': {
    params: UnpublishAdvisorParams
    result: void
  }
  'data.unpublishArticle': {
    params: UnpublishArticleParams
    result: void
  }
  'data.unpublishAssetProfile': {
    params: UnpublishAssetProfileParams
    result: void
  }
  'data.unpublishConferenceEdition': {
    params: UnpublishConferenceEditionParams
    result: void
  }
  'data.unpublishDeal': {
    params: UnpublishDealParams
    result: void
  }
  'data.unpublishIndustry': {
    params: UnpublishIndustryParams
    result: void
  }
  'data.unpublishInvestor': {
    params: UnpublishInvestorParams
    result: void
  }
  'data.unpublishPerson': {
    params: UnpublishPersonParams
    result: void
  }
  'data.updateAddress': {
    params: UpdateAddressParams
    result: Address
  }
  'data.updateAdvisor': {
    params: UpdateAdvisorParams
    result: void
  }
  'data.updateArticle': {
    params: UpdateArticleParams
    result: void
  }
  'data.updateAsset': {
    params: UpdateAssetParams
    result: void
  }
  'data.updateAssetParent': {
    params: UpdateAssetParentParams
    result: void
  }
  'data.updateConference': {
    params: UpdateConferenceParams
    result: void
  }
  'data.updateConferenceEdition': {
    params: UpdateConferenceEditionParams
    result: void
  }
  'data.updateConferenceExhibitor': {
    params: UpdateConferenceExhibitorParams
    result: void
  }
  'data.updateConferenceVenue': {
    params: UpdateConferenceVenueParams
    result: void
  }
  'data.updateDeal': {
    params: UpdateDealParams
    result: void
  }
  'data.updateIndustry': {
    params: UpdateIndustryParams
    result: void
  }
  'data.updateInvestor': {
    params: UpdateInvestorParams
    result: void
  }
  'data.updateLegalEntity': {
    params: UpdateLegalEntityParams
    result: void
  }
  'data.updatePerson': {
    params: UpdatePersonParams
    result: void
  }
  'data.updateTag': {
    params: UpdateTagParams
    result: void
  }
  'data.validateAdvisor': {
    params: ValidateAdvisorParams
    result: Issue[]
  }
  'data.validateArticle': {
    params: ValidateArticleParams
    result: Issue[]
  }
  'data.validateAssetProfile': {
    params: ValidateAssetProfileParams
    result: Issue[]
  }
  'data.validateConferenceEdition': {
    params: ValidateConferenceEditionParams
    result: Issue[]
  }
  'data.validateDeal': {
    params: ValidateDealParams
    result: Issue[]
  }
  'data.validateIndustry': {
    params: ValidateIndustryParams
    result: Issue[]
  }
  'data.validateInvestor': {
    params: ValidateInvestorParams
    result: Issue[]
  }
  'data.validateLegalEntity': {
    params: ValidateLegalEntityParams
    result: Issue[]
  }
  'data.validatePerson': {
    params: ValidatePersonParams
    result: Issue[]
  }
  'email.previewBookmarkUpdatesEmail': {
    params: PreviewBookmarkUpdatesEmailParams
    result: Preview
  }
  'email.previewIndustryEmail': {
    params: PreviewIndustryEmailParams
    result: Preview
  }
  'email.previewSourcingUpdate': {
    params: PreviewSourcingUpdateParams
    result: Preview
  }
  'email.sendBookmarkUpdatesEmail': {
    params: SendBookmarkUpdatesEmailParams
    result: void
  }
  'email.sendIndustryEmail': {
    params: SendIndustryEmailParams
    result: void
  }
  'email.sendSourcingUpdate': {
    params: SendSourcingUpdateParams
    result: void
  }
  'rpc.enums': {
    params: never
    result: EnumInfo[]
  }
  'rpc.error': {
    params: ErrorParams
    result: void
  }
  'rpc.errorCodes': {
    params: never
    result: ErrorInfo[]
  }
  'rpc.ping': {
    params: never
    result: string
  }
  'rpc.services': {
    params: never
    result: RPCServiceInfo[]
  }
  'rpc.types': {
    params: never
    result: TypeInfo[]
  }
}
