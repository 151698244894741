import { useApiKey } from '@gain/cms/api'
import { CopyIcon } from '@gain/components/icons'
import type { ApiKey } from '@gain/rpc/cms-model'
import { isDefined } from '@gain/utils/common'
import { useDialogState } from '@gain/utils/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import {
  InputFieldDate,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'

export interface RouteVenueProps {
  customerId: number
  onCrudAction: () => void
}

export default function RouteApiKey({ customerId, onCrudAction }: RouteVenueProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [newApiKey, setNewApiKey] = useState<string | null>(null)
  const [showDeleteAlert, openDeleteAlert, closeDeleteAlert] = useDialogState()

  const routeMatch = useRouteMatch<{ apiKeyId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)

  const isNew = routeMatch.params.apiKeyId === 'new'

  const inputFormAPI = useInputFormAPI({
    createMethod: 'customer.createApiKey',
    updateMethod: 'customer.updateApiKey',
    deleteMethod: 'customer.deleteApiKey',

    validationSchema: {
      expiresAt: yup.date().nullable().typeError('Enter a valid date'),
    },
  })

  const swrApiKey = useApiKey(() => {
    return isNew ? null : { id: parseInt(routeMatch.params.apiKeyId) }
  })

  useEffect(() => {
    if (isDefined(swrApiKey.data)) {
      inputFormAPI.form.reset(swrApiKey.data, { keepDirty: false, keepDirtyValues: false })
    }
  }, [swrApiKey, inputFormAPI.form])

  const handleClose = useCallback(() => {
    onCrudAction()
    drawerRef.current?.close(true)
  }, [onCrudAction])

  // Copies API key to clipboard
  const copyApiKey = useCallback(async () => {
    if (newApiKey !== null) {
      await navigator.clipboard.writeText(newApiKey)
      enqueueSnackbar('API key copied to clipboard', {
        key: 'api-key-copied',
        variant: 'success',
      })
    }
  }, [enqueueSnackbar, newApiKey])

  const handleUpdateOrCreateApiKey = useCallback(() => {
    inputFormAPI.form.handleSubmit(async (values) => {
      const partial = {
        customerId,
        note: values.note?.trim(),
        expiresAt: values.expiresAt,
      } as Partial<ApiKey>

      if (isNew) {
        await inputFormAPI.create({ partial }, (response: ApiKey) => {
          setNewApiKey(response.key)
        })
      } else {
        if (await inputFormAPI.patch(routeMatch.params.apiKeyId, partial)) {
          handleClose()
        }
      }
    })()
  }, [inputFormAPI, customerId, isNew, routeMatch.params.apiKeyId, handleClose])

  const handleDeleteApiKey = useCallback(async () => {
    const deleted = await inputFormAPI.delete(parseInt(routeMatch.params.apiKeyId, 10))

    if (deleted) {
      closeDeleteAlert()
      onCrudAction()
      drawerRef.current?.close(true)
    }
  }, [closeDeleteAlert, inputFormAPI, onCrudAction, routeMatch.params.apiKeyId])

  return (
    <>
      <RightDrawer
        ref={drawerRef}
        action={
          <Button
            disabled={inputFormAPI.busy}
            onClick={handleUpdateOrCreateApiKey}
            variant={'contained'}>
            Save
          </Button>
        }
        footerAction={
          !isNew && (
            <Button
              color={'error'}
              disabled={inputFormAPI.deleting}
              onClick={openDeleteAlert}
              variant={'contained'}>
              Delete
            </Button>
          )
        }
        title={isNew ? 'New API Key' : 'Edit API Key'}>
        <InputFormProvider form={inputFormAPI.form}>
          <FormControl variant={'outlined'}>
            <InputFieldText
              helperText={'For administration purposes only'}
              label={'Note'}
              name={'note'}
            />
          </FormControl>

          <FormControl variant={'outlined'}>
            <InputFieldDate
              label={'Expires at'}
              name={'expiresAt'}
            />
            <FormHelperText id={'expiresAt'}>Leave empty to never expire</FormHelperText>
          </FormControl>
        </InputFormProvider>
      </RightDrawer>

      <Dialog
        onClose={handleClose}
        open={newApiKey !== null}>
        <DialogTitle>API Key created</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Make sure to copy the API key now. You won’t be able to see it again! Send it to the
            customer's technical contact.
          </DialogContentText>
          <Alert
            action={
              <IconButton
                onClick={copyApiKey}
                sx={{ ml: 0.5 }}>
                <CopyIcon />
              </IconButton>
            }
            icon={false}
            severity={'info'}
            sx={{ mt: 2 }}>
            <code>{newApiKey}</code>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant={'contained'}>
            I have copied the key
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={closeDeleteAlert}
        open={showDeleteAlert}>
        <DialogTitle>Delete API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this API Key? <strong>This cannot be undone</strong>.
            Consider setting an expiry date instead.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color={'error'}
            loading={inputFormAPI.deleting}
            onClick={handleDeleteApiKey}
            variant={'contained'}>
            Delete API Key
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
